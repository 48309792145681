import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import durationImg from 'images/duration.svg';
import targetAreasImg from 'images/target-areas.svg';
import equipmentImg from 'images/equipment-needed.svg';

const Details = ({ levelId, intensityImg }) => {
  const { t } = useTranslation('exercises');

  return (
    <div className="d-md-flex flex-md-wrap justify-content-lg-between">
      <div className="sf-exercises-level-info">
        <h4 className="font-weight-normal mb-3 mb-lg-4">{t('duration.title')}</h4>
        <div className="d-flex align-items-center">
          <img src={durationImg} alt={t('duration.title')} className="mr-3" />
          <span className="font-weight-bold">{t('duration.label')}</span>
        </div>
      </div>
      <div className="sf-exercises-level-info">
        <h4 className="font-weight-normal mb-3 mb-lg-4">{t('intensity.title')}</h4>
        <div className="d-flex align-items-center">
          <img src={intensityImg} alt={t('intensity.title')} className="mr-3" />
          <span className="font-weight-bold">{t(`intensity.label.${levelId}`)}</span>
        </div>
      </div>
      <div className="sf-exercises-level-info">
        <h4 className="font-weight-normal mb-3 mb-lg-4">{t('targetAreas.title')}</h4>
        <div className="d-flex align-items-center">
          <img src={targetAreasImg} alt={t('targetAreas.title')} className="mr-3" />
          <span className="font-weight-bold">{t('targetAreas.label')}</span>
        </div>
      </div>
      <div className="sf-exercises-level-info">
        <h4 className="font-weight-normal mb-3 mb-lg-4">{t('equipmentNeeded.title')}</h4>
        <div className="d-flex align-items-center">
          <img src={equipmentImg} alt={t('equipmentNeeded.title')} className="mr-3" />
          <span className="font-weight-bold">{t('equipmentNeeded.label')}</span>
        </div>
      </div>
    </div>
  );
};

Details.propTypes = {
  levelId: PropTypes.number.isRequired,
  intensityImg: PropTypes.string.isRequired,
};

export default Details;

export const homepageVideos = {
  en: 'https://www.youtube.com/watch?v=Kcx94j88uk4',
  fr: 'https://www.youtube.com/watch?v=2kDgfcHjffc',
};

export const exercisesVideos = {
  level1: {
    sublevel1: {
      en: 'https://www.youtube.com/watch?v=cRh5WkZrvSQ',
      fr: 'https://www.youtube.com/watch?v=q42EQdiMyQ0',
    },
    sublevel2: {
      en: 'https://www.youtube.com/watch?v=VMDyHxPTEw8',
      fr: 'https://www.youtube.com/watch?v=le_IWCRyCpQ',
    },
    sublevel3: {
      en: 'https://www.youtube.com/watch?v=M_tVv42vG34',
      fr: 'https://www.youtube.com/watch?v=lL0Rajmh0z0',
    },
    sublevel4: {
      en: 'https://www.youtube.com/watch?v=BD5s85EinKg',
      fr: 'https://www.youtube.com/watch?v=-RvaNd5eOBE',
    },
    sublevel5: {
      en: 'https://www.youtube.com/watch?v=QIoiJvjdBMQ',
      fr: 'https://www.youtube.com/watch?v=AejUpKm72A4',
    },
  },
  level2: {
    sublevel1: {
      en: 'https://www.youtube.com/watch?v=t6eqGvCn9Cg',
      fr: 'https://www.youtube.com/watch?v=AhLPMsanoTI',
    },
    sublevel2: {
      en: 'https://www.youtube.com/watch?v=-EAJ79_e0BA',
      fr: 'https://www.youtube.com/watch?v=fvZM8s_OhN4',
    },
    sublevel3: {
      en: 'https://www.youtube.com/watch?v=5N6P52yk42Q',
      fr: 'https://www.youtube.com/watch?v=dEluAftHJGA',
    },
    sublevel4: {
      en: 'https://www.youtube.com/watch?v=AsEcO6Ozpo0',
      fr: 'https://www.youtube.com/watch?v=zqrz50Oez8w',
    },
    sublevel5: {
      en: 'https://www.youtube.com/watch?v=-MmzzyQTr6s',
      fr: 'https://www.youtube.com/watch?v=ymS0neiueWQ',
    },
  },
  level3: {
    sublevel1: {
      en: 'https://www.youtube.com/watch?v=dEZY3XQGolo',
      fr: 'https://www.youtube.com/watch?v=CU39JtA1aUU',
    },
    sublevel2: {
      en: 'https://www.youtube.com/watch?v=atjOA919Hjo',
      fr: 'https://www.youtube.com/watch?v=oQQRWRBtDdc',
    },
    sublevel3: {
      en: 'https://www.youtube.com/watch?v=Fx3nh5Rs-1g',
      fr: 'https://www.youtube.com/watch?v=YPalEMBnUpc',
    },
    sublevel4: {
      en: 'https://www.youtube.com/watch?v=pzmNwekyKsw',
      fr: 'https://www.youtube.com/watch?v=mwKhPwX3heI',
    },
    sublevel5: {
      en: 'https://www.youtube.com/watch?v=K6bZWkU7o1Q',
      fr: 'https://www.youtube.com/watch?v=3ku1b6BSXsw',
    },
  },
  level4: {
    sublevel1: {
      en: 'https://www.youtube.com/watch?v=VhRZa8T-Ou0',
      fr: 'https://www.youtube.com/watch?v=HRRiVyy9jAE',
    },
    sublevel2: {
      en: 'https://www.youtube.com/watch?v=MX3Xz7L0SfE',
      fr: 'https://www.youtube.com/watch?v=L12mG_2i0eI',
    },
    sublevel3: {
      en: 'https://www.youtube.com/watch?v=fK8R7wYJYPA',
      fr: 'https://www.youtube.com/watch?v=g5_VP_nZamM',
    },
    sublevel4: {
      en: 'https://www.youtube.com/watch?v=Kdg1IJShW7o  ',
      fr: 'https://www.youtube.com/watch?v=4bmVUQU-34s',
    },
    sublevel5: {
      en: 'https://www.youtube.com/watch?v=1XuOceKiaZE',
      fr: 'https://www.youtube.com/watch?v=E35sChIKEoA',
    },
  },
};

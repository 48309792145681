import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import routes from './routes';
import Route from './Route';

const Router = () => (
  <BrowserRouter>
    <Switch>
      {routes.map(({
        key,
        exact,
        path,
        component,
        layout,
        backToTop,
        getReadyButton,
      }) => (
        <Route
          key={key}
          exact={exact}
          path={path}
          component={component}
          layout={layout}
          backToTop={backToTop}
          getReadyButton={getReadyButton}
        />
      ))}
    </Switch>
  </BrowserRouter>
);

export default Router;

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import JoseImgMobile from 'images/about/team/jose--mobile.jpg';
import JoseImgMobile2x from 'images/about/team/jose--mobile@2x.jpg';
import JoseImgTablet from 'images/about/team/jose--tablet.jpg';
import JoseImgTablet2x from 'images/about/team/jose--tablet@2x.jpg';
import JoseImgDesktop from 'images/about/team/jose--desktop.jpg';
import JoseImgDesktop2x from 'images/about/team/jose--desktop@2x.jpg';

import GuyImgMobile from 'images/about/team/guy--mobile.jpg';
import GuyImgMobile2x from 'images/about/team/guy--mobile@2x.jpg';
import GuyImgTablet from 'images/about/team/guy--tablet.jpg';
import GuyImgTablet2x from 'images/about/team/guy--tablet@2x.jpg';
import GuyImgDesktop from 'images/about/team/guy--desktop.jpg';
import GuyImgDesktop2x from 'images/about/team/guy--desktop@2x.jpg';

import AndreaImgMobile from 'images/about/team/andrea--mobile.jpg';
import AndreaImgMobile2x from 'images/about/team/andrea--mobile@2x.jpg';
import AndreaImgTablet from 'images/about/team/andrea--tablet.jpg';
import AndreaImgTablet2x from 'images/about/team/andrea--tablet@2x.jpg';
import AndreaImgDesktop from 'images/about/team/andrea--desktop.jpg';
import AndreaImgDesktop2x from 'images/about/team/andrea--desktop@2x.jpg';

import Title from 'components/shared/Title';
import ResponsiveImage from 'components/shared/ResponsiveImage';

const teamMembersSrcImages = [
  {
    mobile: JoseImgMobile,
    mobile2x: JoseImgMobile2x,
    tablet: JoseImgTablet,
    tablet2x: JoseImgTablet2x,
    desktop: JoseImgDesktop,
    desktop2x: JoseImgDesktop2x,
  },
  {
    mobile: GuyImgMobile,
    mobile2x: GuyImgMobile2x,
    tablet: GuyImgTablet,
    tablet2x: GuyImgTablet2x,
    desktop: GuyImgDesktop,
    desktop2x: GuyImgDesktop2x,
  },
  {
    mobile: AndreaImgMobile,
    mobile2x: AndreaImgMobile2x,
    tablet: AndreaImgTablet,
    tablet2x: AndreaImgTablet2x,
    desktop: AndreaImgDesktop,
    desktop2x: AndreaImgDesktop2x,
  },
];

const MeetTheTeam = ({ className }) => {
  const { t } = useTranslation('about');
  const componentClasses = classNames('bg-white p-4 p-md-6', className);

  const teamMemberImages = _map(teamMembersSrcImages, (src) => (
    [
      {
        media: 'all and (max-width: 360px)',
        img: src.mobile,
        img2x: src.mobile2x,
      },
      {
        media: 'all and (max-width: 768px)',
        img: src.tablet,
        img2x: src.tablet2x,
      },
      {
        media: 'all and (min-width: 769px)',
        img: src.desktop,
        img2x: src.desktop2x,
      },
    ]));

  return (
    <div className={componentClasses}>
      {
        _map(teamMemberImages, (imageData, index) => {
          const key = `teamMember${index + 1}`;
          const name = t(`${key}.name`);
          const title = t(`${key}.title`);
          const isLast = index + 1 === imageData.length;

          return (
            <article className={`row ${!isLast && 'mb-6'}`} key={name}>
              <div className="col-md-4 mb-4 mb-md-0">
                <ResponsiveImage images={imageData} altText={title} />
              </div>
              <div className="col-md-8">
                <Title size={3}>{name}</Title>
                <Title size={4} className="mb-3 mb-md-4">{title}</Title>
                <Trans i18nKey={`${key}.description`} ns="about" components={{ bold: <strong /> }} />
              </div>
            </article>
          );
        })
      }
    </div>
  );
};

MeetTheTeam.propTypes = {
  className: PropTypes.string,
};

MeetTheTeam.defaultProps = {
  className: '',
};

export default MeetTheTeam;

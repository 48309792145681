import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';

const Faq = ({ title, body }) => {
  const { t } = useTranslation('faq');

  return (
    <article className="sf-faq">
      <div className="container-fluid">
        <div className="row align-items-center mb-3">
          <div className="col-2 pl-0">
            <span className="sf-faq__big-letter d-flex align-items-center justify-content-center bg-quaternary-100 rounded-circle mx-auto">
              Q
            </span>
          </div>
          <div className="col-10 pr-0">
            <h3 className="mb-0">{t(title)}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-10 offset-2 pr-0">
            <div className="container-fluid">
              <div className="row">
                <div className="d-none d-md-block col-1 pl-0">
                  <span className="sf-faq__small-letter d-flex align-items-center justify-content-center bg-quaternary-100 rounded-circle font-weight-bold">
                    {t('answerInitial')}
                  </span>
                </div>
                <div className="sf-faq__body col-12 col-md-11 pr-0 pl-sm-0">
                  <Trans i18nKey={body} ns="faq" components={{ bold: <strong /> }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

Faq.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

export default Faq;

import React from 'react';
import utils from 'common/utils';

const DonationBtn = () => {
  const lang = utils.getLang();

  const donationLink = lang === 'en' ? 'https://action.codevie.ca/site/Donation2?df_id=1560&1560.donation=form1&mfc_pref=T&s_locale=en_CA&set.SingleDesignee=1221&s_src=safewebsite' : 'https://action.codevie.ca/site/Donation2?df_id=1560&1560.donation=form1&mfc_pref=T&s_locale=fr_CA&set.SingleDesignee=1221&s_src=safewebsite';
  const donationText = lang === 'en' ? 'make a donation' : 'faire un don';

  return (
    <a href={donationLink} target="_blank" rel="noreferrer noopener" className="animated-button sf-donation" alt="Make a Donation"><span className="position-relative">{ donationText }</span></a>
  );
};

export default DonationBtn;

import React from 'react';
import { useTranslation } from 'react-i18next';
import ExerciseCard from 'components/home/ExerciseCard';
import _map from 'lodash/map';

import DoImg1 from 'images/recommendations/do-warmup.svg';
import DoImg2 from 'images/recommendations/do-be-patient.svg';
import DoImg3 from 'images/recommendations/do-include-walks.svg';
import DoImg4 from 'images/recommendations/do-tune-in.svg';
import DoImg5 from 'images/recommendations/do-be-consistent.svg';
import DoImg6 from 'images/recommendations/do-it-together.svg';

import StayImg1 from 'images/recommendations/stay-comfortable.svg';
import StayImg2 from 'images/recommendations/stay-cozy.svg';
import StayImg3 from 'images/recommendations/stay-hydrated.svg';
import StayImg4 from 'images/recommendations/stay-safe.svg';
import StayImg5 from 'images/recommendations/stay-clear.svg';
import StayImg6 from 'images/recommendations/stay-bright.svg';

import Title from '../shared/Title';

const deck = [
  {
    title: 'doTitle',
    cards: [
      {
        id: 1,
        title: 'doCard1.title',
        text: 'doCard1.text',
        imgPath: DoImg1,
      },
      {
        id: 2,
        title: 'doCard2.title',
        text: 'doCard2.text',
        imgPath: DoImg2,
      },
      {
        id: 3,
        title: 'doCard3.title',
        text: 'doCard3.text',
        imgPath: DoImg3,
      },
      {
        id: 4,
        title: 'doCard4.title',
        text: 'doCard4.text',
        imgPath: DoImg4,
      },
      {
        id: 5,
        title: 'doCard5.title',
        text: 'doCard5.text',
        imgPath: DoImg5,
      },
      {
        id: 6,
        title: 'doCard6.title',
        text: 'doCard6.text',
        imgPath: DoImg6,
      },
    ],
  },
  {
    title: 'stayTitle',
    cards: [
      {
        id: 1,
        title: 'stayCard1.title',
        text: 'stayCard1.text',
        imgPath: StayImg1,
      },
      {
        id: 2,
        title: 'stayCard2.title',
        text: 'stayCard2.text',
        imgPath: StayImg2,
      },
      {
        id: 3,
        title: 'stayCard3.title',
        text: 'stayCard3.text',
        imgPath: StayImg3,
      },
      {
        id: 4,
        title: 'stayCard4.title',
        text: 'stayCard4.text',
        imgPath: StayImg4,
      },
      {
        id: 5,
        title: 'stayCard5.title',
        text: 'stayCard5.text',
        imgPath: StayImg5,
      },
      {
        id: 6,
        title: 'stayCard6.title',
        text: 'stayCard6.text',
        imgPath: StayImg6,
      },
    ],
  },
];

const RecommendationsItems = () => {
  const { t } = useTranslation('recommendations');

  return (
    <section className="sf-exercises py-0">
      <div className="sf-container">
        {
          _map(deck, (deckItem, index) => (
            <div className="row mx-0" key={deckItem.title}>
              <Title className={`col-12 mb-5 ${index && 'mt-4 mt-md-6'}`}>{t(deckItem.title)}</Title>
              {_map(deckItem.cards, (card) => (
                <ExerciseCard data={card} key={card.title} ns="recommendations" />
              ))}
            </div>
          ))
        }
      </div>
    </section>
  );
};

export default RecommendationsItems;

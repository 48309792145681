import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import AnimatedLink from 'components/shared/AnimatedLink';
import TriangleContainer from 'components/shared/TriangleContainer';
import ResponsiveImage from 'components/shared/ResponsiveImage';
import utils from 'common/utils';
import pathNames from 'router/path-names';

import howItWorksMobile1X from 'images/how-it-works--mobile.webp';
import howItWorksMobile2X from 'images/how-it-works--mobile@2x.webp';
import howItWorksTablet1X from 'images/how-it-works--tablet.webp';
import howItWorksTablet2X from 'images/how-it-works--tablet@2x.webp';
import howItWorksDesktop1X from 'images/how-it-works--desktop.webp';
import howItWorksDesktop2X from 'images/how-it-works--desktop@2x.webp';

const images = [
  {
    media: 'all and (max-width: 480px)',
    img: howItWorksMobile1X,
    img2x: howItWorksMobile2X,
    type: 'image/webp',
  },
  {
    media: 'all and (max-width: 768px)',
    img: howItWorksTablet1X,
    img2x: howItWorksTablet2X,
    type: 'image/webp',
  },
  {
    media: 'all and (min-width: 769px)',
    img: howItWorksDesktop1X,
    img2x: howItWorksDesktop2X,
    type: 'image/webp',
  },
];

const HowItWorks = () => {
  const { t } = useTranslation('home');
  const lang = utils.getLang();

  return (
    <TriangleContainer theme="tertiary" className="sf-hiw">
      <div className="sf-container row">
        <div className="col-12 col-lg-6 order-1 order-lg-0 pr-lg-5">
          <ResponsiveImage images={images} altText={t('howItWorksTitle')} />
        </div>
        <div className="col-12 col-lg-6 order-0 order-lg-1">
          <h2 className="sf-hiw__title font-weight-bold mb-4">{t('howItWorksTitle')}</h2>
          <p className="sf-hiw__text">
            <Trans i18nKey="howItWorksText" ns="home">
              Before you start, be sure to click on the
              <AnimatedLink to={pathNames.recommendations[lang]} type="link">
                safe recommendations
              </AnimatedLink>
              . These important tips will help prepare you for your exercise session.
            </Trans>
          </p>
          <ul className="list-unstyled">
            <li className="d-flex mb-3 mb-lg-4">
              <span className="sf-hiw__letter bg-secondary d-flex justify-content-center align-items-center flex-shrink-0 text-white rounded-circle font-weight-bold mr-4">A</span>
              <p className="sf-hiw__text mb-0">
                <Trans i18nKey="howItWorksA" ns="home">
                  Go to
                  <AnimatedLink to={pathNames.exercises[lang]} type="link">
                    Start Exercising
                  </AnimatedLink>
                  to access the videos.
                </Trans>
              </p>
            </li>
            <li className="d-flex mb-3 mb-lg-4">
              <span className="sf-hiw__letter bg-secondary d-flex justify-content-center align-items-center flex-shrink-0 text-white rounded-circle font-weight-bold mr-4">B</span>
              <p className="sf-hiw__text mb-0">
                <Trans i18nKey="howItWorksB" ns="home" components={{ bold: <strong /> }} />
              </p>
            </li>
            <li className="d-flex mb-3 mb-lg-4">
              <span className="sf-hiw__letter bg-secondary d-flex justify-content-center align-items-center flex-shrink-0 text-white rounded-circle font-weight-bold mr-4">C</span>
              <p className="sf-hiw__text mb-0">
                <Trans i18nKey="howItWorksC" ns="home" components={{ bold: <strong /> }} />
              </p>
            </li>
            <li className="d-flex mb-3 mb-lg-4">
              <span className="sf-hiw__letter bg-secondary d-flex justify-content-center align-items-center flex-shrink-0 text-white rounded-circle font-weight-bold mr-4">D</span>
              <p className="sf-hiw__text mb-0">
                <Trans i18nKey="howItWorksD" ns="home" components={{ bold: <strong /> }} />
              </p>
            </li>
          </ul>
          <AnimatedLink to={pathNames.recommendations[lang]} type="button" altStyle className="mt-3">
            {t('home:howItWorksButton')}
          </AnimatedLink>
        </div>
      </div>
    </TriangleContainer>
  );
};

export default HowItWorks;

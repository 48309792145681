import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cancelIcon from 'images/cancel.svg';
import checkIcon from 'images/check.svg';

const RightForYouList = ({ items, check }) => {
  const { t } = useTranslation('home');

  return (
    <ul className="list-unstyled mb-0">
      {items.map((item) => (
        <li className="d-flex px-3 px-md-4 px-xl-5 mt-4" key={`rfye-${item.id}`}>
          <img className="sf-rfy__list-cancel align-self-start mr-3" src={check ? checkIcon : cancelIcon} alt={check ? t('rightForYouCheck') : t('rightForYouCancel')} />
          <span>{t(item.text)}</span>
        </li>
      ))}
    </ul>
  );
};

RightForYouList.propTypes = {
  items: PropTypes.array.isRequired,
  check: PropTypes.bool,
};

RightForYouList.defaultProps = {
  check: true,
};

export default RightForYouList;

import React from 'react';
import PropTypes from 'prop-types';

export const VIDEO_COMPLETED_EVENT_NAME = 'videoComplete';
export const LEVEL_COMPLETED_EVENT_NAME = 'levelComplete';

export const AnalyticsContext = React.createContext({
  onVideoCompleted: () => {},
  onLevelCompleted: () => {},
  onVideoPlayed: () => {},
});

const AnalyticsProvider = ({ children }) => {
  const onVideoCompleted = (videoTitle, videoLevel) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: VIDEO_COMPLETED_EVENT_NAME,
        'gtm.videoPercent': '100%',
        'gtm.videoProvider': 'Vimeo',
        'gtm.videoTitle': videoTitle,
        'gtm.videoStatus': 'completed',
        videoTitle,
        videoLevel,
      });
    }
  };

  const onLevelCompleted = (level, forced = 0) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: LEVEL_COMPLETED_EVENT_NAME,
        level,
        forced,
      });
    }
  };

  const onVideoPlayed = () => {
    const LINKEDIN_CONVERSION_ID = 11416353;
    window.lintrk('track', { conversion_id: LINKEDIN_CONVERSION_ID });
  };

  const defaultValue = {
    onVideoCompleted,
    onLevelCompleted,
    onVideoPlayed,
  };

  return (
    <AnalyticsContext.Provider value={defaultValue}>
      { children }
    </AnalyticsContext.Provider>
  );
};

AnalyticsProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AnalyticsProvider;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Testimonial from 'components/home/Testimonial';

import GuyImg from 'images/guy-home.webp';
import AndreaImg from 'images/andrea-home.webp';

const items = [
  {
    id: 1,
    name: 'testimonials.1.name',
    text: 'testimonials.1.text',
    imgPath: GuyImg,
  },
  {
    id: 2,
    name: 'testimonials.2.name',
    text: 'testimonials.2.text',
    imgPath: AndreaImg,
  },
];

const Testimonials = () => {
  const { t } = useTranslation('home');

  return (
    <section className="sf-testimonials">
      <div className="sf-container">
        <h2 className="mx-3 font-weight-bold">{t('testimonialsTitle')}</h2>
        <div className="row mx-0">
          <div className="col-12 col-lg-6 mb-4 mb-lg-0">
            <Testimonial data={items[0]} />
          </div>
          <div className="col-12 col-lg-6">
            <Testimonial data={items[1]} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';

const AnimatedLink = ({
  to, type, altStyle, children, noAnimate, onClick,
}) => {
  const match = useRouteMatch({
    path: to,
    exact: true,
  });

  const classes = classNames({
    'animated-link': type === 'link',
    'animated-link-text': type === 'text-link',
    'animated-link-text--alt': type === 'text-link' && altStyle,
    'animated-button': type === 'button',
    'animated-button--alt': type === 'button' && altStyle,
    'animated-link--active': match && type === 'link',
    'animated-button--active': match && type === 'button',
    'animated-button--alt--active': match && type === 'button' && altStyle,
    'no-animate': noAnimate,
  });

  const onClickLink = (e) => {
    if (onClick && typeof onClick === 'function') {
      onClick(e);
    }
  };

  const isMail = to.indexOf('mailto') === 0;

  if (!isMail) {
    return (<Link to={to} onClick={onClickLink} className={classes}><span className="position-relative">{children}</span></Link>);
  }

  return (<a href={to} className={classes} target="_blank" rel="noreferrer noopener"><span className="position-relative">{children}</span></a>);
};

AnimatedLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  altStyle: PropTypes.bool,
  noAnimate: PropTypes.bool,
  onClick: PropTypes.func,
};

AnimatedLink.defaultProps = {
  to: '#',
  type: 'link',
  altStyle: false,
  noAnimate: false,
  onClick: () => {},
};

export default AnimatedLink;

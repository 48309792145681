import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import MobileMenuContext from 'context/MobileMenuContext';
import AnalyticsProvider from './AnalyticsContext';

const AppContextProvider = ({ children }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const updateMobileMenu = (open) => {
    setMobileMenu(open);
  };

  const mobileMenuValue = useMemo(() => ({
    mobileMenu,
    setMobileMenu: updateMobileMenu,
  }), [mobileMenu]);

  return (
    <AnalyticsProvider>
      <MobileMenuContext.Provider
        value={mobileMenuValue}
      >
        {children}
      </MobileMenuContext.Provider>
    </AnalyticsProvider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.object.isRequired,
};

export default AppContextProvider;

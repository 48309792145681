import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AnimatedLink from 'components/shared/AnimatedLink';
import LanguageSwitcher from 'components/shared/LanguageSwitcher';
import utils from 'common/utils';
import pathNames from 'router/path-names';

const FooterMenu = () => {
  const { t } = useTranslation('footer');
  const lang = utils.getLang();

  const containerClasses = classNames(
    'footer-menu',
    'd-md-flex',
    'flex-row',
    'align-items-center',
    'flex-wrap',
    'justify-content-between',
  );
  const currentYear = new Date().getFullYear();
  const copy = `© ${currentYear}`;

  return (
    <section className={containerClasses}>
      <ul className="list-unstyled mb-3 mb-md-0">
        <li className="list-item mr-4 mb-3 mb-md-0">
          <LanguageSwitcher classes="btn btn-link animated-link" />
        </li>
        <li className="list-item mr-4 mb-3 mb-md-0">
          <AnimatedLink to={pathNames.privacy[lang]}>
            {t('footer:policy')}
          </AnimatedLink>
        </li>
        <li className="list-item mr-4 mb-3 mb-md-0">
          <AnimatedLink to={pathNames.about[lang]}>
            {t('footer:about')}
          </AnimatedLink>
        </li>
        <li className="list-item mr-4 mb-3 mb-md-0">
          <AnimatedLink to={pathNames.contact[lang]}>
            {t('footer:contact')}
          </AnimatedLink>
        </li>
      </ul>
      <span className="copy">
        {copy}
        {' '}
        {t('footer:copy')}
      </span>
    </section>
  );
};

export default FooterMenu;

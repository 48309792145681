import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ResponsiveImage from 'components/shared/ResponsiveImage';
import Title from 'components/shared/Title';

import dashUrl from 'images/dash.svg';
import plusUrl from 'images/plus.svg';

const Article = ({
  text,
  title,
  date,
  author,
  image,
  className,
  isCollapsed,
  onChange,
}) => {
  const { t } = useTranslation('resources');
  const componentClasses = classNames(
    'article',
    'position-relative',
    'mb-4',
    className,
    {
      open: !isCollapsed,
    },
  );
  const authorString = author ? `${t('by')} ${author}` : null;
  const showMore = () => {
    if (typeof onChange === 'function') {
      onChange(title);
    }
  };
  const images = [
    {
      media: 'all and (max-width: 0px)',
      img: `${image}.jpg`,
      img2x: `${image}@2x.jpg`,
    },
  ];

  // Split the Markdown content into lines
  const lines = text?.split('\n');

  // Extract the second and third lines
  const firstParagraph = lines.slice(1, 2).join('\n');

  // Extract the remaining content
  const remainingText = lines.slice(2).join('\n');

  return (
    <article className={componentClasses}>
      <div className="row">
        <div className="image col-md-4 mb-4 mb-md-0 text-center">
          <ResponsiveImage images={images} altText={title} />
        </div>
        <div className="text position-relative mt-4 col-md-8 d-flex flex-column">
          <button type="button" onClick={showMore} className="btn rounded-circle d-flex align-items-center justify-content-center position-absolute">
            <img src={isCollapsed ? plusUrl : dashUrl} alt="" />
          </button>
          {date && <div className="date font-weight-bold mb-3">{date}</div>}
          <Title size={2} className="mb-3 title">{title}</Title>
          {authorString && <div className="author font-weight-bold ">{authorString}</div>}
          <ReactMarkdown className="mt-3 text-break">{firstParagraph}</ReactMarkdown>

          <div className="text-content">
            { !isCollapsed
              && (<ReactMarkdown linkTarget="_blank" className="text-break" components={{ sup: <sup /> }}>{remainingText}</ReactMarkdown>)}
          </div>
        </div>
      </div>
    </article>
  );
};

Article.defaultProps = {
  className: null,
  image: null,
  text: '',
  date: null,
  author: null,
  isCollapsed: true,
  onChange: () => {},
};

Article.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  isCollapsed: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Article;

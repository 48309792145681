import React, { useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Intro from 'components/shared/Intro';
import ResponsiveImage from 'components/shared/ResponsiveImage';
import TriangleContainer from 'components/shared/TriangleContainer';
import Seo from 'components/shared/Seo';
import pathNames from 'router/path-names';
import AnimatedLink from 'components/shared/AnimatedLink';
import CustomModal from 'components/shared/CustomModal';

import utils from 'common/utils';

import contactImgMobile1X from 'images/contact--mobile.webp';
import contactImgMobile2X from 'images/contact--mobile@2x.webp';
import contactImgTablet1X from 'images/contact--tablet.webp';
import contactImgTablet2X from 'images/contact--tablet@2x.webp';
import contactImgDesktop1X from 'images/contact--desktop.webp';
import contactImgDesktop2X from 'images/contact--desktop@2x.webp';
import ContactForm from './ContactForm';

const images = [
  {
    media: 'all and (max-width: 480px)',
    img: contactImgMobile1X,
    img2x: contactImgMobile2X,
    type: 'image/webp',
  },
  {
    media: 'all and (max-width: 768px)',
    img: contactImgTablet1X,
    img2x: contactImgTablet2X,
    type: 'image/webp',
  },
  {
    media: 'all and (min-width: 769px)',
    img: contactImgDesktop1X,
    img2x: contactImgDesktop2X,
    type: 'image/webp',
  },
];

const Contact = () => {
  const { t } = useTranslation('contact');
  const lang = utils.getLang();

  /* Thank you Modal */
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const title = (
    <h1 className="font-weight-normal"><Trans i18nKey="intro.title" ns="contact" components={{ bold: <strong /> }} /></h1>
  );

  const text = (
    <div>
      <p>
        <Trans i18nKey="intro.text1" ns="contact" components={{ bold: <strong /> }} />
      </p>
      <p>
        <Trans i18nKey="intro.text2" ns="contact">
          You can also check out our
          <AnimatedLink to={pathNames.faq[lang]} type="text-link">
            FAQs
          </AnimatedLink>
          section in case we&#39;ve already answered your question.
        </Trans>
      </p>
    </div>
  );

  const media = <ResponsiveImage className="w-100" images={images} altText={t('pageTitle')} />;

  return (
    <section className="contact-us">
      <Seo
        title={t('pageTitle')}
        description={t('pageDescription')}
      />
      <Intro title={title} text={text} media={media} />
      <TriangleContainer
        theme="primary"
        triangleTheme="primary-200"
        underIntro
      >
        <ContactForm
          formSubmitFunc={handleShow}
        />
      </TriangleContainer>
      <CustomModal
        show={modalShow}
        handleShow={handleShow}
        handleClose={handleClose}
        modalBody={(
          <p className="h5">
            {t('successMessage')}
          </p>
        )}
      />
    </section>
  );
};

export default Contact;

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ArrowLink from 'components/shared/ArrowLink';
import FooterLogos from 'components/shared/footer/FooterLogos';
import FooterMenu from 'components/shared/footer/FooterMenu';
import pathNames from 'router/path-names';
import utils from 'common/utils';

const Footer = ({
  showReadyButton,
  showLogos,
  showMenu,
}) => {
  const { t } = useTranslation('footer');
  const containerClasses = classNames('footer pt-5 pb-5', 'container-fluid', {
    'triangle position-relative overflow-hidden': showReadyButton,
  });
  const lang = utils.getLang();
  const readyButtonUrl = (pathNames.exercises && pathNames.exercises[lang]) || '';

  return (
    <footer className={containerClasses}>
      {
        showReadyButton && <div className="sf-container"><ArrowLink to={readyButtonUrl}>{t('getReady')}</ArrowLink></div>
      }
      {
        showLogos && <FooterLogos />
      }
      {
        showMenu && <FooterMenu />
      }
    </footer>
  );
};

Footer.propTypes = {
  showReadyButton: PropTypes.bool,
  showLogos: PropTypes.bool,
  showMenu: PropTypes.bool,
};

Footer.defaultProps = {
  showReadyButton: true,
  showLogos: true,
  showMenu: true,
};

export default Footer;

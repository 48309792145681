import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import LevelProgressContext from 'context/LevelProgressContext';
import utils from 'common/utils';
import cancelIcon from 'images/cancel-primary.svg';
import playIcon from 'images/play.svg';

const ContinueModal = () => {
  const { t } = useTranslation('exercises');
  const modal = useRef();
  const [open, setOpen] = useState(false);
  const { levelProgress, setLevelProgress } = useContext(LevelProgressContext);
  const { previousLevel, currentLevel } = levelProgress;
  const { showCompletionModal } = levelProgress?.[previousLevel] || false;

  useEffect(() => {
    if (previousLevel !== currentLevel && showCompletionModal) {
      const updatedLevelModalVisibility = utils.setLevelModalVisibility(
        levelProgress, previousLevel,
      );

      setLevelProgress(updatedLevelModalVisibility);
      setOpen(true);
      disableBodyScroll(modal.current);
    }
  }, [currentLevel, previousLevel, showCompletionModal, levelProgress, setLevelProgress]);

  const closeModal = () => {
    setOpen(false);
    enableBodyScroll(modal.current);
  };

  const goToLevel = () => {
    const element = document.getElementById(`level-${currentLevel}`);
    enableBodyScroll(modal.current);
    setOpen(false);
    element.scrollIntoView();
  };

  return (
    <div
      ref={modal}
      className={classNames(
        'sf-exercises-modal position-fixed bg-white',
        { 'sf-exercises-modal--visible': open },
      )}
    >
      <h3>
        <Trans i18nKey="continueTitle" ns="exercises" values={{ level: previousLevel }} />
      </h3>
      <p>{t('continueText')}</p>
      <div className="d-flex flex-column flex-md-row align-items-md-center">
        <button
          type="button"
          className="btn p-0 mb-4 mb-md-0 mr-md-5 d-flex align-items-center align-self-start align-self-md-baseline"
          onClick={closeModal}
        >
          {t('finishForToday')}
          <img src={cancelIcon} alt={t('cancel')} className="ml-2" />
        </button>
        <button
          type="button"
          className="btn animated-button align-self-start align-self-md-baseline px-5"
          onClick={goToLevel}
        >
          <span className="position-relative">
            <Trans i18nKey="goToNextLevel" ns="exercises" values={{ level: currentLevel }} />
            <img src={playIcon} alt={t('goToNextLevel')} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ContinueModal;

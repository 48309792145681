import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Title = ({
  size,
  children,
  className,
  weight,
}) => {
  const classes = classNames(
    'sf-title',
    className,
    {
      [`font-weight-${weight}`]: weight,
      [`h${size}`]: size,
    },
  );

  switch (size) {
    case 3:
      return (<h3 className={classes}>{children}</h3>);
    case 5:
      return (<h5 className={classes}>{children}</h5>);
    case 6:
      return (<h6 className={classes}>{children}</h6>);
    case 2:
    default:
      return <h2 className={classes}>{children}</h2>;
  }
};

Title.defaultProps = {
  size: 2,
  children: null,
  className: null,
  weight: 'bold',
};

Title.propTypes = {
  size: PropTypes.number,
  children: PropTypes.any,
  className: PropTypes.string,
  weight: PropTypes.string,
};

export default Title;

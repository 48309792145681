import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import LevelProgressContext from 'context/LevelProgressContext';
import UnlockLevelContext from 'context/UnlockLevelContext';
import utils from 'common/utils';
import cancelIcon from 'images/cancel-primary.svg';
import playIcon from 'images/play.svg';
import { AnalyticsContext } from 'context/AnalyticsContext';

const UnlockModal = () => {
  const { t } = useTranslation('exercises');
  const modal = useRef();
  const [open, setOpen] = useState(false);
  const { levelToUnlock, setLevelToUnlock } = useContext(UnlockLevelContext);
  const { levelProgress, setLevelProgress } = useContext(LevelProgressContext);
  const { onLevelCompleted } = useContext(AnalyticsContext);
  const previousLevel = levelToUnlock - 1;

  useEffect(() => {
    if (levelToUnlock) {
      setOpen(true);
      disableBodyScroll(modal.current);
    }
  }, [levelToUnlock]);

  const goToPreviousLevel = () => {
    setOpen(false);
    enableBodyScroll(modal.current);
    utils.scrollToElement(`level-${previousLevel}`);
    setLevelToUnlock(null);
  };

  const unlockLevel = () => {
    const levelToTrack = levelToUnlock - 1;
    onLevelCompleted(levelToTrack, 1);
    const updatedLevelUnlocked = utils.setLevelUnlocked(levelProgress, levelToUnlock);
    setLevelProgress(updatedLevelUnlocked);
    setOpen(false);
    enableBodyScroll(modal.current);
    utils.scrollToElement(`level-${levelToUnlock}`);
    setLevelToUnlock(null);
  };

  return (
    <div
      ref={modal}
      className={classNames(
        'sf-exercises-modal position-fixed bg-white',
        { 'sf-exercises-modal--visible': open },
      )}
    >
      <h3>
        <Trans i18nKey="unlockTitle" ns="exercises" values={{ level: previousLevel }} />
      </h3>
      <p>
        <Trans i18nKey="unlockText" ns="exercises" values={{ level: previousLevel }} />
      </p>
      <div className="d-flex flex-column flex-md-row align-items-md-center">
        <button
          type="button"
          className="btn p-0 mb-4 mb-md-0 mr-md-5 d-flex align-items-center align-self-start align-self-md-baseline"
          onClick={goToPreviousLevel}
        >
          <Trans i18nKey="goToLevel" ns="exercises" values={{ level: previousLevel }} />
          <img src={cancelIcon} alt={t('cancel')} className="ml-2" />
        </button>
        <button
          type="button"
          className="btn animated-button align-self-start align-self-md-baseline px-2 px-md-3"
          onClick={unlockLevel}
        >
          <span className="position-relative">
            <Trans i18nKey="startLevel" ns="exercises" values={{ level: levelToUnlock }} />
            <img src={playIcon} alt={t('goToNextLevel')} />
          </span>
        </button>
      </div>
    </div>
  );
};

export default UnlockModal;

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const Seo = ({
  title,
  description,
  image,
}) => {
  const currentUrl = window.location.href;

  return (
    <React.Fragment>
      {image ? (
        <Helmet>
          {/* general tags */ }
          <title>{title}</title>
          <meta name="description" content={description} />
          {/* openGraph tags */ }
          <meta name="og:url" content={currentUrl} />
          <meta name="og:title" content={title} />
          <meta name="og:description" content={description} />
          <meta name="og:image" content={image.url} />
          <meta property="og:image:width" content={image.width} />
          <meta property="og:image:height" content={image.height} />
          <meta name="og:type" content="website" />
          <meta name="fb:app_id" content="" />
          {/* twitter Card tags */ }
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={image.url} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content="" />
        </Helmet>
      ) : (
        <Helmet>
          {/* general tags */}
          <title>{title}</title>
          <meta name="description" content={description} />
          {/* openGraph tags */}
          <meta name="og:url" content={currentUrl} />
          <meta name="og:title" content={title} />
          <meta name="og:description" content={description} />
          <meta name="og:type" content="website" />
          <meta name="fb:app_id" content="" />
          {/* twitter Card tags */}
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:creator" content="" />
        </Helmet>
      )}
    </React.Fragment>
  );
};

Seo.defaultProps = {
  image: null,
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.objectOf(PropTypes.string),
};

export default Seo;

import React from 'react';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import utils from 'common/utils';
import pathNames from 'router/path-names';

const LanguageSwitcher = ({ classes }) => {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation('header');
  const lang = utils.getLang();
  const langToSwitch = lang === 'en' ? 'fr' : 'en';

  const changeLang = () => {
    const { pathname } = location;
    const pathnameArray = pathname.split('/');

    i18n.changeLanguage(langToSwitch);

    if (pathnameArray[1] === 'fr') {
      const pathObject = _find(pathNames, { fr: pathname });
      history.push(pathObject.en);
    } else {
      const pathObject = _find(pathNames, { en: pathname });
      history.push(pathObject.fr);
    }
  };

  return (
    <button
      type="button"
      className={classNames('sf-translation btn btn-link', classes)}
      onClick={changeLang}
    >
      {t('header:altLang')}
    </button>
  );
};

LanguageSwitcher.defaultProps = {
  classes: '',
};

LanguageSwitcher.propTypes = {
  classes: PropTypes.string,
};

export default LanguageSwitcher;

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoEn } from 'images/logos/en/logo.svg';
import { ReactComponent as LogoFr } from 'images/logos/fr/logo.svg';
import HeaderMenu from 'components/shared/header/HeaderMenu';
import LanguageSwitcher from 'components/shared/LanguageSwitcher';
import utils from 'common/utils';
import pathNames from 'router/path-names';
import DonationBtn from '../DonationBtn';
import AnimatedLink from '../AnimatedLink';

const Header = () => {
  const lang = utils.getLang();
  const { t } = useTranslation('header');

  const logo = lang === 'en' ? <LogoEn className="sf-logo" /> : <LogoFr className="sf-logo" />;

  return (
    <header className="sf-header">
      <div className="d-none d-lg-flex align-items-center justify-content-end mt-lg-3">
        <DonationBtn />
        <div className="sf-contact-btn ml-4">
          <AnimatedLink to={pathNames.contact[lang]}>
            {t('header:contact')}
          </AnimatedLink>
        </div>
        <LanguageSwitcher classes="animated-link p-0 ml-4" />
      </div>
      <div className="position-relative d-lg-flex align-items-lg-center">
        <Link to={pathNames.home[lang]} title="Safe - Senior adult fitness exercises">
          {logo}
        </Link>
        <HeaderMenu />
      </div>
    </header>
  );
};

export default Header;

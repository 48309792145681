import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import RightForYouList from 'components/home/RightForYouList';

const experienceItems = [
  { id: 1, text: 'rightForYouExperience.item1' },
  { id: 2, text: 'rightForYouExperience.item2' },
  { id: 3, text: 'rightForYouExperience.item3' },
  { id: 4, text: 'rightForYouExperience.item4' },
  { id: 5, text: 'rightForYouExperience.item5' },
  { id: 6, text: 'rightForYouExperience.item6' },
  { id: 7, text: 'rightForYouExperience.item7' },
  { id: 8, text: 'rightForYouExperience.item8' },
];

const helpItems = [
  { id: 1, text: 'rightForYouHelp.item1' },
  { id: 2, text: 'rightForYouHelp.item2' },
  { id: 3, text: 'rightForYouHelp.item3' },
  { id: 4, text: 'rightForYouHelp.item4' },
  { id: 5, text: 'rightForYouHelp.item5' },
  { id: 6, text: 'rightForYouHelp.item6' },
  { id: 7, text: 'rightForYouHelp.item7' },
  { id: 8, text: 'rightForYouHelp.item8' },
];

const RightForYou = () => {
  const { t } = useTranslation('home');

  return (
    <section className="sf-rfy bg-primary">
      <div className="sf-container">
        <div className="row mx-0 pb-3">
          <div className="col-12 col-lg-5">
            <h2 className="font-weight-bold">{t('rightForYouTitle')}</h2>
          </div>
          <div className="col-12 col-lg-7">
            <ul className="list-unstyled mb-0 mt-3 mt-md-4 mt-lg-0">
              <li className="d-flex pl-2 mb-3">
                <span className="sf-rfy__list-dot flex-shrink-0 rounded-circle bg-gray mr-3" />
                <span><Trans i18nKey="rightForYouList1" ns="home" components={{ bold: <strong /> }} /></span>
              </li>
              <li className="d-flex pl-2 mb-3">
                <span className="sf-rfy__list-dot flex-shrink-0 rounded-circle bg-gray mr-3" />
                <span>{t('rightForYouList2')}</span>
              </li>
              <li className="d-flex pl-2">
                <span className="sf-rfy__list-dot flex-shrink-0 rounded-circle bg-gray mr-3" />
                <span><Trans i18nKey="rightForYouList3" ns="home" components={{ bold: <strong /> }} /></span>
              </li>
            </ul>
          </div>
        </div>
        <div className="bg-white mx-3 mt-3 mt-md-4 mt-xl-5">
          <div className="row mx-0">
            <div className="col-12 col-md-6">
              <h3 className="font-weight-bold px-3 px-md-4 px-xl-5 mb-0">{t('rightForYouExperience.title')}</h3>
              <RightForYouList items={experienceItems} check={false} />
            </div>
            <div className="col-12 col-md-6">
              <h3 className="font-weight-bold px-3 px-md-4 px-xl-5 mb-0">{t('rightForYouHelp.title')}</h3>
              <RightForYouList items={helpItems} />
            </div>
          </div>
          <p className="sf-rfy__bottom-text font-weight-bold text-primary mx-3 mb-0 text-center">{t('rightForYouBottomText')}</p>
        </div>
      </div>
    </section>
  );
};

export default RightForYou;

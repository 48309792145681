import localforage from 'localforage';

const getLang = () => {
  const { location: { pathname } } = window;
  const pathArray = pathname.split('/');

  return pathArray[1] === 'fr' ? 'fr' : 'en';
};

const getItemFromLs = async (key, defaultValue) => {
  try {
    const value = await localforage.getItem(key);
    return value || defaultValue;
  } catch {
    return defaultValue;
  }
};

const saveItemOnLs = (key, value) => localforage.setItem(key, value);

const scrollToElement = (id) => {
  const element = document.getElementById(id);
  element.scrollIntoView();
};

const setCurrentSublevel = (levelProgress, sublevel, levelId) => ({
  ...levelProgress,
  [levelId]: {
    ...levelProgress[levelId],
    current: sublevel,
  },
  currentSublevel: sublevel,
});

const setSublevelsCompleted = (levelProgress, levelId, sublevel) => ({
  ...levelProgress,
  [levelId]: {
    ...levelProgress[levelId],
    sublevelsCompleted: [...levelProgress[levelId].sublevelsCompleted, sublevel.id],
  },
});

const setLevelCompleted = (levelProgress, levelId) => {
  let currentLevel = levelId;

  if (levelId !== 4) {
    currentLevel += 1;
  }

  return {
    ...levelProgress,
    [levelId]: {
      ...levelProgress[levelId],
      completed: true,
    },
    previousLevel: levelId,
    currentLevel,
  };
};

const setLevelUnlocked = (levelProgress, levelId) => ({
  ...levelProgress,
  [levelId]: {
    ...levelProgress[levelId],
    unlocked: true,
  },
});

const setLevelModalVisibility = (levelProgress, levelId) => ({
  ...levelProgress,
  [levelId]: {
    ...levelProgress[levelId],
    showCompletionModal: false,
  },
});

const getImageFullPath = (imagePath) => window.origin + imagePath;

export const decodeHtmlString = (string) => string.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));

export default {
  getLang,
  getItemFromLs,
  saveItemOnLs,
  scrollToElement,
  setCurrentSublevel,
  setSublevelsCompleted,
  setLevelCompleted,
  setLevelUnlocked,
  setLevelModalVisibility,
  getImageFullPath,
};

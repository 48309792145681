import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import Intro from 'components/shared/Intro';
import ResponsiveImage from 'components/shared/ResponsiveImage';
import TriangleContainer from 'components/shared/TriangleContainer';
import Seo from 'components/shared/Seo';
import utils from 'common/utils';

import recommendationsImgMobile1X from 'images/safe-recommendations--mobile.webp';
import recommendationsImgMobile2X from 'images/safe-recommendations--mobile@2x.webp';
import recommendationsImgTablet1X from 'images/safe-recommendations--tablet.webp';
import recommendationsImgTablet2X from 'images/safe-recommendations--tablet@2x.webp';
import recommendationsImgDesktop1X from 'images/safe-recommendations--desktop.webp';
import recommendationsImgDesktop2X from 'images/safe-recommendations--desktop@2x.webp';
import metaImageUrl from 'images/safe-recommendations--tablet.jpg';
import RecommendationsItems from './RecommendationsItems';

const images = [
  {
    media: 'all and (max-width: 480px)',
    img: recommendationsImgMobile1X,
    img2x: recommendationsImgMobile2X,
    type: 'image/webp',
  },
  {
    media: 'all and (max-width: 768px)',
    img: recommendationsImgTablet1X,
    img2x: recommendationsImgTablet2X,
    type: 'image/webp',
  },
  {
    media: 'all and (min-width: 769px)',
    img: recommendationsImgDesktop1X,
    img2x: recommendationsImgDesktop2X,
    type: 'image/webp',
  },
];

const metaImage = {
  url: utils.getImageFullPath(metaImageUrl),
  width: '737',
  height: '551',
};

const Recommendations = () => {
  const { t } = useTranslation('recommendations');
  const media = <ResponsiveImage images={images} altText={t('pageTitle')} />;

  const text = (
    <p><Trans i18nKey="intro.text" ns="recommendations" components={{ bold: <strong /> }} /></p>
  );

  const title = (
    <h1 className="font-weight-normal"><Trans i18nKey="intro.title" ns="recommendations" components={{ bold: <strong /> }} /></h1>
  );

  return (
    <section className="sf-recommendations">
      <Seo
        title={t('pageTitle')}
        description={t('pageDescription')}
        image={metaImage}
      />
      <Intro title={title} text={text} media={media} />
      <TriangleContainer
        theme="primary"
        triangleTheme="primary-200"
        underIntro
      >
        <RecommendationsItems />
      </TriangleContainer>
    </section>
  );
};

export default Recommendations;

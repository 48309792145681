const pathNames = {
  home: {
    en: '/',
    fr: '/fr',
  },
  recommendations: {
    en: '/recommendations',
    fr: '/fr/recommandations',
  },
  resources: {
    en: '/resources',
    fr: '/fr/ressources',
  },
  faq: {
    en: '/faq',
    fr: '/fr/faq',
  },
  exercises: {
    en: '/exercises',
    fr: '/fr/exercices',
  },
  privacy: {
    en: '/privacy',
    fr: '/fr/confidentialite',
  },
  about: {
    en: '/about',
    fr: '/fr/a-propos',
  },
  contact: {
    en: '/contact',
    fr: '/fr/contact',
  },
};

export default pathNames;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { ReactComponent as Heart } from 'images/heart.svg';

const ExerciseCard = ({ data, ns }) => {
  const { t } = useTranslation(ns);

  return (
    <div className="col-12 col-md-6 col-xl-4 mb-4">
      <article className={classNames('exercise-card', { 'exercise-card--alt': data.alt })}>
        <div className="text-right">
          {data.alt ? (
            <span className="exercise-card__sign d-inline-flex justify-content-center align-items-center font-weight-bold bg-white rounded-circle">
              <Heart />
            </span>
          ) : (
            <span className="exercise-card__number d-inline-flex justify-content-center align-items-center text-secondary font-weight-bold bg-tertiary rounded-circle">{data.id}</span>
          )}
        </div>
        {!data.alt && <img className="mb-md-4" src={data.imgPath} alt={t(data.title)} />}
        {!data.alt && <h3 className="font-weight-bold mb-1">{t(data.title)}</h3>}
        <p className="m-0">
          <Trans i18nKey={data.text} ns={ns} components={{ bold: <strong /> }} />
        </p>
      </article>
    </div>
  );
};

ExerciseCard.propTypes = {
  data: PropTypes.object.isRequired,
  ns: PropTypes.string,
};

ExerciseCard.defaultProps = {
  ns: 'home',
};

export default ExerciseCard;

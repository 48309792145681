import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

const messagesArray = [
  'message1',
  'message2',
  'message3',
  'message4',
  'message5',
];

const Messages = ({ levelId, completed }) => {
  const messages = messagesArray.map((message, index) => (
    <div
      className={classNames('sf-exercises-message flex-grow-1 text-center', { 'sf-exercises-message--visible': completed === (index + 1) })}
      key={message}
    >
      <Trans
        i18nKey={message}
        ns="exercises"
        components={{ bold: <strong /> }}
        values={{ level: levelId }}
      />
    </div>
  ));

  return (
    <div className="sf-exercises-messages d-flex">
      {messages}
    </div>
  );
};

Messages.propTypes = {
  levelId: PropTypes.number.isRequired,
  completed: PropTypes.number.isRequired,
};

export default Messages;

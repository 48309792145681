import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import _get from 'lodash/get';
import utils from 'common/utils';
import LogoEn from 'images/logos/en/logo-white.svg';
import LogoFr from 'images/logos/fr/logo-white.svg';
import PlayerButton from 'components/exercises/PlayerButton';
import { getLevelPreviewPromise } from 'common/exercises';
import LevelProgressContext from 'context/LevelProgressContext';
import UnlockLevelContext from 'context/UnlockLevelContext';
import { AnalyticsContext } from 'context/AnalyticsContext';
import arrowForwardImg from 'images/arrow-forward-white.svg';
import classNames from 'classnames';

const Video = ({ levelId, sublevels }) => {
  const player = useRef();
  const [playing, setPlaying] = useState(false);
  const [previewUrl, setPreviewUrl] = useState('');
  const { t } = useTranslation('exercises');
  const lang = utils.getLang();
  const { levelProgress, setLevelProgress } = useContext(LevelProgressContext);
  const { onVideoCompleted, onLevelCompleted, onVideoPlayed } = useContext(AnalyticsContext);
  const { setLevelToUnlock } = useContext(UnlockLevelContext);
  const currentSublevel = _get(levelProgress, `${levelId}.current`, sublevels[0]);
  const unlocked = _get(levelProgress, `${levelId}.unlocked`, false);

  const logoClasses = classNames(
    'img-fluid sf-exercises-video-logo position-absolute',
    { 'sf-exercises-video-logo--hidden': playing },
  );

  const logo = lang === 'en' ? LogoEn : LogoFr;

  useEffect(() => {
    setPlaying(false);
    player.current.showPreview();
  }, [currentSublevel]);

  useEffect(() => {
    const previewPromise = getLevelPreviewPromise(levelId, currentSublevel.id);

    previewPromise.then((url) => {
      setPreviewUrl(url.default);
    });
  }, [levelId, currentSublevel]);

  const onClickPreview = () => {
    if (unlocked) {
      setPlaying(true);
      onVideoPlayed();
    } else {
      setLevelToUnlock(levelId);
      player.current.showPreview();
    }
  };

  const onVideoEnd = () => {
    onVideoCompleted(currentSublevel.title, levelProgress.currentLevel);
    // If sublevel isn't in the list of sublevels completed
    if (!levelProgress[levelId].sublevelsCompleted.includes(currentSublevel.id)) {
      // Get an updated progress object adding the completed sublevel to the list
      const updatedSublevelsCompleted = utils.setSublevelsCompleted(
        levelProgress, levelId, currentSublevel,
      );
      /**
       * Check if all sublevels from a level where completed
       * If so, then set the level as completed and unlock the next one
       * Otherwise, update progress on localstorage
       */
      if (updatedSublevelsCompleted[levelId].sublevelsCompleted.length === sublevels.length) {
        const updatedLevelCompleted = utils.setLevelCompleted(updatedSublevelsCompleted, levelId);
        const { currentLevel } = updatedLevelCompleted;
        const updatedLevelUnlocked = utils.setLevelUnlocked(updatedLevelCompleted, currentLevel);
        setLevelProgress(updatedLevelUnlocked);
        /* track the current level was completed */
        onLevelCompleted(levelProgress.currentLevel);
      } else {
        setLevelProgress(updatedSublevelsCompleted);
      }
    }
  };

  return (
    <div className="sf-exercises-video-wrapper col-12 col-xl-10 position-relative overflow-hidden">
      <ReactPlayer
        ref={player}
        className="sf-exercises-video-player bg-gray-300"
        url={currentSublevel.videoUrl[lang]}
        playing={playing}
        controls
        width="100%"
        height="100%"
        light={previewUrl}
        playIcon={unlocked ? <PlayerButton type="play" /> : <PlayerButton type="unlock" />}
        config={{
          youtube: {
            playerVars: {
              playsinline: true,
              color: '#FFFFFF',
            },
          },
        }}
        onClickPreview={onClickPreview}
        onEnded={onVideoEnd}
      />
      <img className={logoClasses} src={logo} alt="" />
      <div className={classNames(
        'sf-exercises-video-title position-absolute',
        { 'sf-exercises-video-title--hidden': playing },
      )}
      >
        <h3 className="mb-0 font-weight-bold text-white d-flex align-items-center">
          {t('level')}
          {' '}
          {levelId}
          <img src={arrowForwardImg} alt={`${t('level')} ${levelId}`} />
        </h3>
      </div>
    </div>
  );
};

Video.propTypes = {
  levelId: PropTypes.number.isRequired,
  sublevels: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Video;

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import _map from 'lodash/map';
import classNames from 'classnames';

import Intro from 'components/shared/Intro';
import ResponsiveImage from 'components/shared/ResponsiveImage';
import TriangleContainer from 'components/shared/TriangleContainer';
import BackToTop from 'components/shared/BackToTop';
import Title from 'components/shared/Title';
import Seo from 'components/shared/Seo';
import utils from 'common/utils';

import aboutImgMobile1X from 'images/about/about--mobile.webp';
import aboutImgMobile2X from 'images/about/about--mobile@2x.webp';
import aboutImgTablet1X from 'images/about/about--tablet.webp';
import aboutImgTablet2X from 'images/about/about--tablet@2x.webp';
import aboutImgDesktop1X from 'images/about/about--desktop.webp';
import aboutImgDesktop2X from 'images/about/about--desktop@2x.webp';
import metaImageUrl from 'images/about/about--tablet.jpg';

import mcGillLogo from 'images/logos/en/mcgill-logo.svg';
import codeLogoEn from 'images/logos/en/code-life-logo.png';
import codeLogoFr from 'images/logos/fr/code-life-logo-fr.png';
import gdfLogo from 'images/logos/en/gdf-logo.svg';

import MeetTheTeam from './MeetTheTeam';

const images = [
  {
    media: 'all and (max-width: 360px)',
    img: aboutImgMobile1X,
    img2x: aboutImgMobile2X,
    type: 'image/webp',
  },
  {
    media: 'all and (max-width: 767px)',
    img: aboutImgTablet1X,
    img2x: aboutImgTablet2X,
    type: 'image/webp',
  },
  {
    media: 'all and (min-width: 768px)',
    img: aboutImgDesktop1X,
    img2x: aboutImgDesktop2X,
    type: 'image/webp',
  },
];

const metaImage = {
  url: utils.getImageFullPath(metaImageUrl),
  width: '737',
  height: '551',
};

const About = () => {
  const { t } = useTranslation('about');
  const lang = utils.getLang();
  const media = <ResponsiveImage images={images} altText={t('pageTitle')} />;

  const acknowledgements = [
    {
      src: gdfLogo,
      alt: 'Fondation Grace Dart',
      to: lang === 'en' ? 'https://fondationgracedart.com/' : 'https://fondationgracedart.com/fr',
      height: 50,
      langKey: 'acknowledgement2',
    },
  ];

  const logos = [
    {
      src: mcGillLogo,
      to: 'https://www.mcgill.ca/geriatrics/',
      height: 47,
      langKey: 'mcGill',
    },
    {
      src: lang === 'en' ? codeLogoEn : codeLogoFr,
      to: lang === 'en' ? 'https://www.mghfoundation.com/en' : 'https://www.mghfoundation.com/fr',
      height: 198,
      langKey: 'codeLife',
      itemClass: 'w-75',
    },
  ];

  const text = (
    <p><Trans i18nKey="intro.text" ns="about" components={{ bold: <strong /> }} /></p>
  );

  const title = (
    <h1 className="font-weight-normal"><Trans i18nKey="intro.title" ns="about" components={{ bold: <strong /> }} /></h1>
  );

  return (
    <section className="sf-about">
      <Seo
        title={t('pageTitle')}
        description={t('pageDescription')}
        image={metaImage}
      />
      <BackToTop />
      <Intro title={title} text={text} media={media} />
      <TriangleContainer
        theme="primary"
        triangleTheme="primary-200"
        underIntro
      >
        <div className="col-12 team">
          <Title>{t('meetTheTeam')}</Title>
          <MeetTheTeam className="mt-6" />
        </div>
        <div className="col-12">
          <div className="bg-white p-4 p-md-6">
            <div className="separator bg-primary" />
          </div>
        </div>
        <div className="col-12">
          {
            _map(logos, (logo) => {
              const { langKey } = logo;
              const companyTitle = t(`${langKey}.title`);
              const descriptionKey = `${langKey}.description`;

              return (
                <article className="bg-white p-4 p-md-5" key={langKey}>
                  <div className="row">
                    <div className="col-md-4 mb-4 mb-md-0 pr-4">
                      <a href={logo.to} target="_blank" rel="noreferrer" key={langKey}>
                        <img
                          src={logo.src}
                          alt={companyTitle}
                          className={classNames('logo img-fluid', logo.itemClass)}
                          height={logo.height}
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <Title size={3} className="mb-3 mb-md-4">{companyTitle}</Title>
                      <Trans i18nKey={descriptionKey} ns="about" components={{ bold: <strong />, italic: <i /> }} />
                    </div>
                  </div>
                </article>
              );
            })
          }
        </div>
        <div className="col-12">
          <div className="bg-white">
            <Title size={3} className="p-4 p-md-5 mb-0">{t('acknowledgementSectionTitle')}</Title>
          </div>
          {
            _map(acknowledgements, (acknowledgement) => {
              const { langKey } = acknowledgement;
              const descriptionKey = `${langKey}.description`;

              return (
                <article className="bg-white p-4 p-md-5" key={langKey}>
                  <div className="row">
                    <div className="col-md-4 mb-4 mb-md-0 pr-4">
                      <a href={acknowledgement.to} target="_blank" rel="noreferrer" key={langKey}>
                        <img
                          src={acknowledgement.src}
                          alt={acknowledgement.alt}
                          className={classNames('logo img-fluid', acknowledgement.itemClass)}
                          height={acknowledgement.height}
                        />
                      </a>
                    </div>
                    <div className="col-md-8">
                      <Trans i18nKey={descriptionKey} ns="about" components={{ bold: <strong /> }} />
                    </div>
                  </div>
                </article>
              );
            })
          }
        </div>
      </TriangleContainer>
    </section>
  );
};

export default About;

import React from 'react';
import { useTranslation } from 'react-i18next';
import LevelContextProvider from 'context/LevelContextProvider';
import Introduction from 'components/exercises/Introduction';
import { levels } from 'common/exercises';
import Level from 'components/exercises/Level';
import ContinueModal from 'components/exercises/ContinueModal';
import UnlockModal from 'components/exercises/UnlockModal';
import Seo from 'components/shared/Seo';
import utils from 'common/utils';
import metaImageUrl from 'images/about/about--tablet.jpg';

const metaImage = {
  url: utils.getImageFullPath(metaImageUrl),
  width: '737',
  height: '551',
};

const Exercises = () => {
  const { t } = useTranslation('exercises');

  const levelList = levels.map((level) => (
    <Level
      id={level.id}
      sublevels={level.sublevels}
      intensityImg={level.intensityImg}
      color={level.color}
      key={level.id}
    />
  ));

  return (
    <React.Fragment>
      <Seo
        title={t('pageTitle')}
        description={t('pageDescription')}
        image={metaImage}
      />
      <LevelContextProvider>
        <Introduction />
        <div className="sf-exercises-levels sf-container px-3">
          {levelList}
        </div>
        <ContinueModal />
        <UnlockModal />
      </LevelContextProvider>
    </React.Fragment>
  );
};

export default Exercises;

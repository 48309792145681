import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { useTranslation } from 'react-i18next';
import utils from 'common/utils';
import FormField from 'components/shared/form/FormField';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const templateID = {
  en: 'template_i1k55pj',
  fr: 'template_t491zsp',
};

const emailJSConfig = {
  serviceID: 'service_pvlcs5a',
  publicKey: 'j171QWS53sAOVQeMN',
};

const ContactForm = ({
  formSubmitFunc,
}) => {
  const lang = utils.getLang();
  const { t } = useTranslation('contact');
  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => responseMessage && setResponseMessage(''), 10000);
    return () => clearTimeout(timer);
  }, [responseMessage]);

  /* Contact Form Fields */
  const formFields = [
    {
      id: 'formField1',
      type: 'text',
      name: 'user_first_name',
      label: t('formField1.label'),
      placeholder: t('formField1.placeholder'),
      validationRules: {
        required: { value: true, message: t('validationMessages.required') },
      },
      columnWidth: '50',
    },
    {
      id: 'formField2',
      type: 'text',
      name: 'user_last_name',
      label: t('formField2.label'),
      placeholder: t('formField2.placeholder'),
      validationRules: {
        required: { value: true, message: t('validationMessages.required') },
      },
      columnWidth: '50',
    },
    {
      id: 'formField3',
      type: 'email',
      name: 'user_email',
      label: t('formField3.label'),
      placeholder: t('formField3.placeholder'),
      validationRules: {
        required: { value: true, message: t('validationMessages.required') },
        pattern: { value: /^\S+@\S+$/i, message: t('validationMessages.email') },
      },
      columnWidth: '50',
    },
    {
      id: 'formField4',
      type: 'select',
      name: 'user_info',
      label: t('formField4.label'),
      placeholder: t('formField4.placeholder'),
      validationRules: {
        required: { value: true, message: t('validationMessages.required') },
      },
      selectOptions: [
        { id: 1, value: t('formField4.options.1') },
        { id: 2, value: t('formField4.options.2') },
        { id: 3, value: t('formField4.options.3') },
        { id: 4, value: t('formField4.options.4') },
      ],
      columnWidth: '50',
    },
    {
      id: 'formField5',
      type: 'textarea',
      name: 'user_message',
      label: t('formField5.label'),
      placeholder: t('formField5.placeholder'),
      validationRules: {
        required: { value: true, message: t('validationMessages.required') },
      },
      columnWidth: '100',
    },
    {
      id: 'formField6',
      type: 'radio',
      name: 'user_newsletter',
      label: t('formField6.label'),
      placeholder: t('formField6.placeholder'),
      validationRules: {
        required: { value: true, message: t('validationMessages.required') },
      },
      columnWidth: '100',
    },
    {
      id: 'formField7',
      type: 'radio',
      name: 'user_newsletter',
      label: t('formField7.label'),
      placeholder: t('formField7.placeholder'),
      validationRules: {
        required: { value: true, message: t('validationMessages.required') },
      },
      columnWidth: '100',
    },
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const form = useRef();
  const sendEmail = () => {
    setIsSubmitting(true);
    try {
      emailjs.sendForm(
        emailJSConfig.serviceID,
        templateID[lang],
        form.current,
        emailJSConfig.publicKey,
      )
        .then((result) => {
          if (result) {
            formSubmitFunc();
            setIsSubmitting(false);
          }
        }, (error) => {
          if (error) {
            setResponseMessage(t('errorMessage'));
            setIsSubmitting(false);
          }
        });
    } catch (error) {
      setResponseMessage(t('errorMessage'));
      setIsSubmitting(false);
    }
    reset();
  };

  return (
    <div className="sf-contact">
      <div className="sf-contact__title-wrapper row m-0 mb-4">
        <h2 className="sf-contact__title col-12 col-lg-9 mb-3">{t('contactFormTitle')}</h2>
        <div className="sf-contact__note col-12 col-lg-3">
          <p>{t('contactFormNote')}</p>
        </div>
        <div className="col-12">
          {
            responseMessage !== '' && <p>{responseMessage}</p>
          }
        </div>
      </div>
      <form
        className="sf-contact__form col-12"
        ref={form}
        onSubmit={handleSubmit(sendEmail)}
      >
        <div className="row">
          {
            formFields.map((formField) => {
              const errorObj = Object.entries(errors).find(([key]) => key === formField.name);
              return (
                <div
                  key={formField.id}
                  className={classNames(
                    'sf-contact__field-wrapper mb-4 col-12',
                    { 'col-md-6': formField.columnWidth === '50' },
                    { 'col-md-12': formField.columnWidth === '100' },
                  )}
                >
                  <FormField
                    type={formField.type}
                    name={formField.name}
                    label={formField.label}
                    placeholder={formField.placeholder}
                    id={formField.id}
                    register={{
                      ...register(formField.name,
                        formField.validationRules !== undefined && formField.validationRules),
                    }}
                    error={errorObj}
                    {...formField.selectOptions !== undefined
                      && { options: formField.selectOptions }}
                  />
                </div>
              );
            })
          }
        </div>
        <div className="col-12 col-md-6 mx-auto mt-4">
          <button type="submit" className="btn animated-button animated-button--alt sf-contact__submit-btn" disabled={isSubmitting}>
            <span className="position-relative">{t('submitBtn')}</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;

ContactForm.propTypes = {
  formSubmitFunc: PropTypes.func.isRequired,
};

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import utils from 'common/utils';

// Translations
import headerEn from 'locales/en/header.json';
import footerEn from 'locales/en/footer.json';
import homeEn from 'locales/en/home.json';
import exercisesEn from 'locales/en/exercises.json';
import resourcesEn from 'locales/en/resources.json';
import faqEn from 'locales/en/faq.json';
import recommendationsEn from 'locales/en/recommendations.json';
import aboutEn from 'locales/en/about.json';
import privacyEn from 'locales/en/privacy-disclaimer.json';
import commonEn from 'locales/en/common.json';
import contactEn from 'locales/en/contact-us.json';

import headerFr from 'locales/fr/header.json';
import footerFr from 'locales/fr/footer.json';
import homeFr from 'locales/fr/home.json';
import exercisesFr from 'locales/fr/exercises.json';
import resourcesFr from 'locales/fr/resources.json';
import faqFr from 'locales/fr/faq.json';
import recommendationsFr from 'locales/fr/recommendations.json';
import aboutFr from 'locales/fr/about.json';
import privacyFr from 'locales/fr/privacy-disclaimer.json';
import commonFr from 'locales/fr/common.json';
import contactFr from 'locales/fr/contact-us.json';

const lng = utils.getLang();

const resources = {
  en: {
    header: headerEn,
    footer: footerEn,
    home: homeEn,
    exercises: exercisesEn,
    resources: resourcesEn,
    faq: faqEn,
    recommendations: recommendationsEn,
    about: aboutEn,
    privacy: privacyEn,
    common: commonEn,
    contact: contactEn,
  },
  fr: {
    header: headerFr,
    footer: footerFr,
    home: homeFr,
    exercises: exercisesFr,
    resources: resourcesFr,
    faq: faqFr,
    recommendations: recommendationsFr,
    about: aboutFr,
    privacy: privacyFr,
    common: commonFr,
    contact: contactFr,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

import React, { useLayoutEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import chevronUpIcon from 'images/chevron-up.svg';

const APPEAR_DISTANCE = 200;
const DEBOUNCE_TIME = 300;
const COMPONENT_CLASS = 'sf-scroll-to-top';

const BackToTop = () => {
  const { t } = useTranslation('common');
  const [isVisible, setIsVisible] = useState(false);
  const classes = classNames(
    COMPONENT_CLASS,
    'btn',
    'rounded-circle',
    'd-flex',
    'align-items-center',
    'justify-content-center',
    'position-fixed',
    'p-0', {
      [`${COMPONENT_CLASS}--visible`]: isVisible,
    },
  );
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useLayoutEffect(() => {
    const onScroll = debounce(() => {
      const shouldBeVisible = window.scrollY >= APPEAR_DISTANCE;
      if (shouldBeVisible !== isVisible) {
        setIsVisible(shouldBeVisible);
      }
    }, DEBOUNCE_TIME);

    window.addEventListener('scroll', onScroll);
    onScroll();

    return () => window.removeEventListener('scroll', onScroll);
  }, [isVisible, setIsVisible]);

  return (
    <button
      type="button"
      className={classes}
      onClick={scrollToTop}
      aria-label={t('backToTop')}
      title={t('backToTop')}
    >
      <img src={chevronUpIcon} alt={t('backToTop')} />
    </button>
  );
};

export default BackToTop;

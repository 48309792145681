import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import utils from 'common/utils';

import mcGillLogo from 'images/logos/en/mcgill-logo.svg';
import codeLogoEn from 'images/logos/en/logo.svg';
import codeLogoFr from 'images/logos/fr/logo.svg';
import codeVieLogoEn from 'images/logos/en/code-life-logo.png';
import codeVieLogoFr from 'images/logos/fr/code-life-logo-fr.png';
import gdfLogo from 'images/logos/en/gdf-logo.svg';
import scotiaBankLogoEn from 'images/logos/en/scotia-bank-logo.svg';
import scotiaBankLogoFr from 'images/logos/fr/scotia-bank-logo.svg';
import saputoLogo from 'images/logos/en/saputo_logo.png';

export const ALIGNMENT = {
  END: 'end',
};

const FooterLogos = ({ alignment }) => {
  const containerClasses = classNames(
    'footer-logos',
    'd-flex',
    'flex-row',
    'flex-wrap',
    'align-items-center',
    'justify-content-between',
    `justify-content-lg-${alignment}`,
  );

  const lang = utils.getLang();

  const logos = [
    {
      id: 1,
      src: mcGillLogo,
      to: 'https://www.mcgill.ca/geriatrics/',
      blank: true,
      alt: '',
      title: 'McGill',
      height: 40,
      itemClass: 'mr-5 mr-md-0 mr-lg-5',
    },
    {
      id: 2,
      src: lang === 'en' ? codeVieLogoEn : codeVieLogoFr,
      to: lang === 'en' ? 'https://www.mghfoundation.com/en' : 'https://www.mghfoundation.com/fr',
      blank: true,
      alt: '',
      title: lang === 'en' ? 'Code Life' : 'Code Vie',
      height: 50,
      itemClass: 'small mr-5',
    },
    {
      id: 3,
      src: gdfLogo,
      to: lang === 'en' ? 'https://fondationgracedart.com/' : 'https://fondationgracedart.com/fr',
      blank: true,
      alt: '',
      title: 'Grace Dart Foundation',
      height: 57,
      itemClass: 'small mr-4 mr-lg-5',
    },
    {
      id: 4,
      src: saputoLogo,
      to: lang === 'en' ? 'https://www.alfoundation.ca/' : 'https://www.alfoundation.ca/fr',
      blank: true,
      alt: '',
      title: 'Saputo Foundation',
      height: 60,
      itemClass: 'mr-5 mr-md-0 mr-lg-5',
    },
    {
      id: 5,
      src: lang === 'en' ? scotiaBankLogoEn : scotiaBankLogoFr,
      to: lang === 'en' ? 'https://www.scotiabank.com/ca/en/personal/bank-your-way/online-banking-seniors.html' : 'https://www.scotiabank.com/ca/fr/particuliers/ma-banque-a-moi/services-pour-aines/outils-et-ressources-financieres.html',
      blank: true,
      alt: '',
      title: lang === 'en' ? 'Scotia Bank' : 'Banque Scotia',
      height: 25,
      itemClass: 'small logo-small-height mr-3 mr-lg-5',
    },
    {
      id: 6,
      src: lang === 'en' ? codeLogoEn : codeLogoFr,
      to: lang === 'en' ? '/' : '/fr',
      alt: '',
      title: lang === 'en' ? 'Safe - senior adult fitness exercies' : 'Safe - soutien aux aînés pour la forme et l\'équilibre',
      height: 83,
      itemClass: 'small',
    },
  ];

  return (
    <section className={containerClasses}>
      {
        logos && logos.map((logo) => (
          <a href={logo.to} target={logo.blank && '_blank'} key={logo.id} title={logo.title}>
            <img
              src={logo.src}
              alt={logo.alt}
              className={classNames('logo', logo.itemClass)}
              height={logo.height}
              loading="lazy"
            />
          </a>
        ))
      }
    </section>
  );
};

FooterLogos.propTypes = {
  alignment: PropTypes.oneOf([
    ALIGNMENT.END,
  ]),
};
FooterLogos.defaultProps = {
  alignment: ALIGNMENT.END,
};

export default FooterLogos;

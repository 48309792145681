import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import LevelProgressContext from 'context/LevelProgressContext';
import Video from 'components/exercises/Video';
import ProgressBar from 'components/exercises/ProgressBar';
import Messages from 'components/exercises/Messages';
import Details from 'components/exercises/Details';
import Sublevel from 'components/exercises/Sublevel';

const Level = ({
  id, sublevels, intensityImg, color,
}) => {
  const { t } = useTranslation('exercises');
  const { levelProgress } = useContext(LevelProgressContext);
  const sublevelsCompleted = _get(levelProgress, `${id}.sublevelsCompleted`, []);

  const sublevelsList = sublevels.map((sublevel) => (
    <Sublevel
      id={sublevel.id}
      videoUrl={sublevel.videoUrl}
      title={sublevel.title}
      levelId={id}
      thumbnailPromise={sublevel.thumbnailPromise}
      key={`${id}-${sublevel.id}`}
    />
  ));

  return (
    <article id={`level-${id}`} className="sf-exercises-level">
      <header className={`sf-exercises-level-header sf-exercises-level-header--${color} d-flex align-items-center position-relative overflow-hidden`}>
        <h2 className="font-weight-bold mb-0">
          {t('level')}
          {' '}
          {id}
        </h2>
      </header>
      <div className="row">
        <Video levelId={id} sublevels={sublevels} />
        <div className="sf-exercises-sublevels col-12 col-xl-2 d-flex flex-xl-column flex-wrap flex-xl-nowrap">
          {sublevelsList}
        </div>
      </div>
      <div className="d-none d-md-block">
        <ProgressBar
          sublevels={sublevels}
          completed={sublevelsCompleted.length}
          color={color}
        />
        <Messages
          levelId={id}
          completed={sublevelsCompleted.length}
        />
      </div>
      <Details levelId={id} intensityImg={intensityImg} />
    </article>
  );
};

Level.propTypes = {
  id: PropTypes.number.isRequired,
  sublevels: PropTypes.arrayOf(PropTypes.object).isRequired,
  intensityImg: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default Level;

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import LevelProgressContext from 'context/LevelProgressContext';
import UnlockLevelContext from 'context/UnlockLevelContext';
import { initialProgress } from 'common/exercises';
import utils from 'common/utils';

const LevelContextProvider = ({ children }) => {
  const [levelProgress, setLevelProgress] = useState({});
  const [levelToUnlock, setLevelToUnlock] = useState(null);

  useEffect(() => {
    utils.getItemFromLs('exercises', initialProgress).then((value) => {
      setLevelProgress(value);
    });
  }, [setLevelProgress]);

  const updateProgress = (value) => {
    setLevelProgress(value);
    utils.saveItemOnLs('exercises', value);
  };

  const progressValue = useMemo(() => ({
    levelProgress,
    setLevelProgress: updateProgress,
  }), [levelProgress]);

  const updateLevelToUnlock = (value) => {
    setLevelToUnlock(value);
  };

  const levelToUnlockValue = useMemo(() => ({
    levelToUnlock,
    setLevelToUnlock: updateLevelToUnlock,
  }), [levelToUnlock]);

  return (
    <LevelProgressContext.Provider
      value={progressValue}
    >
      <UnlockLevelContext.Provider
        value={levelToUnlockValue}
      >
        {children}
      </UnlockLevelContext.Provider>
    </LevelProgressContext.Provider>
  );
};

LevelContextProvider.propTypes = {
  children: PropTypes.array.isRequired,
};

export default LevelContextProvider;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import QuoteIcon from 'images/quote.svg';

const Testimonial = ({ data }) => {
  const { t } = useTranslation('home');

  return (
    <div className="sf-testimonial bg-white">
      <div className="row">
        <div className="col-12 col-md-3 col-lg-4">
          <img className="img-fluid sf-testimonial__img" src={data.imgPath} alt={t(data.name)} />
        </div>
        <div className="col-12 col-md-9 col-lg-8">
          <img className="mt-4 sf-testimonial__quote-icon" src={QuoteIcon} alt="" />
          <p className="mt-4">{t(data.text)}</p>
          <div className="d-flex">
            <span className="font-weight-bold flex-shrink-0">&mdash;</span>
            <span className="font-weight-bold">{t(data.name)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

Testimonial.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Testimonial;

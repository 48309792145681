import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from 'components/shared/header';
import Footer from 'components/shared/footer';
import BackToTop from 'components/shared/BackToTop';

const HEADER_HEIGHT = 100;

const DefaultLayout = ({
  component: Component,
  backToTop,
  getReadyButton: showReadyButton,
  ...rest
}) => {
  useEffect(() => {
    if (window.scrollY > HEADER_HEIGHT) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  });

  return (
    <React.Fragment>
      <Header />
      <main>
        { backToTop && <BackToTop /> }
        <Component {...rest} />
      </main>
      <Footer showReadyButton={showReadyButton} />
    </React.Fragment>
  );
};

DefaultLayout.propTypes = {
  component: PropTypes.func.isRequired,
  backToTop: PropTypes.bool,
  getReadyButton: PropTypes.bool,
};

DefaultLayout.defaultProps = {
  backToTop: false,
  getReadyButton: true,
};

export default DefaultLayout;

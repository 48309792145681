import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AnimatedLink from 'components/shared/AnimatedLink';
import utils from 'common/utils';
import pathNames from 'router/path-names';
import HelpfulResourcesImgMobile1X from 'images/helpful-resources-mobile.webp';
import HelpfulResourcesImgMobile2X from 'images/helpful-resources-mobile@2x.webp';
import HelpfulResourcesImgTablet1X from 'images/helpful-resources-tablet.webp';
import HelpfulResourcesImgTablet2X from 'images/helpful-resources-tablet@2x.webp';
import HelpfulResourcesImgDesktop1X from 'images/helpful-resources-desktop.webp';
import HelpfulResourcesImgDesktop2X from 'images/helpful-resources-desktop@2x.webp';
import ResponsiveImage from '../shared/ResponsiveImage';

const images = [
  {
    media: 'all and (max-width: 480px)',
    img: HelpfulResourcesImgMobile1X,
    img2x: HelpfulResourcesImgMobile2X,
    type: 'image/webp',
  },
  {
    media: 'all and (max-width: 768px)',
    img: HelpfulResourcesImgTablet1X,
    img2x: HelpfulResourcesImgTablet2X,
    type: 'image/webp',
  },
  {
    media: 'all and (min-width: 769px)',
    img: HelpfulResourcesImgDesktop1X,
    img2x: HelpfulResourcesImgDesktop2X,
    type: 'image/webp',
  },
];

const HelpfulResources = () => {
  const { t } = useTranslation('home');
  const lang = utils.getLang();

  return (
    <section className="sf-home-resources">
      <div className="sf-container">
        <div className="row mx-0">
          <div className="sf-home-resources__img animated-image col-lg-6 pr-lg-0">
            <div className="image bg-secondary">
              <Link to={pathNames.resources[lang]}>
                <ResponsiveImage images={images} altText={t('helpfulResourcesTitle')} fluid={false} />
              </Link>
            </div>
          </div>
          <div className="col-lg-6 pl-lg-0">
            <div className="sf-home-resources__info bg-secondary text-white d-lg-flex flex-lg-column justify-content-lg-center align-items-lg-start h-100">
              <h2 className="font-weight-bold mb-4">{t('helpfulResourcesTitle')}</h2>
              <p className="mb-4">{t('helpfulResourcesText')}</p>
              <AnimatedLink to={pathNames.resources[lang]} type="button">
                {t('helpfulResourcesButton')}
              </AnimatedLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HelpfulResources;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ProgressBar = ({ sublevels, completed, color }) => {
  const steps = sublevels.map((item, index) => {
    const isCompleted = completed >= (index + 1);
    const bgClass = `bg-${color}`;

    return (
      <div
        className={classNames(
          'sf-exercises-progress__steps flex-grow-1',
          `sf-exercises-progress__steps--${color}`,
          { [bgClass]: isCompleted },
        )}
        key={item.id}
      />
    );
  });

  return (
    <div className="sf-exercises-progress d-flex">
      {steps}
    </div>
  );
};

ProgressBar.propTypes = {
  sublevels: PropTypes.array.isRequired,
  completed: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default ProgressBar;

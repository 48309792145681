import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

const CustomModal = ({
  show,
  handleShow,
  handleClose,
  modalBtnName,
  modalTitle,
  modalBody,
}) => (
  <div>
    { modalBtnName && (
      <Button variant="primary" onClick={handleShow}>
        { modalBtnName }
      </Button>
    ) }
    <Modal contentClassName="mx-3" show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{ modalTitle }</Modal.Title>
      </Modal.Header>
      <Modal.Body>{ modalBody }</Modal.Body>
      <Modal.Footer />
    </Modal>
  </div>
);

export default CustomModal;

CustomModal.propTypes = {
  show: PropTypes.bool,
  handleShow: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  modalBtnName: PropTypes.string,
  modalTitle: PropTypes.string,
  modalBody: PropTypes.element.isRequired,
};

CustomModal.defaultProps = {
  show: false,
  modalBtnName: '',
  modalTitle: '',
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import HelpfulResourcesImgMobile1X from 'images/helpful-resources-page--mobile.webp';
import HelpfulResourcesImgMobile2X from 'images/helpful-resources-page--mobile@2x.webp';
import HelpfulResourcesImgTablet1X from 'images/helpful-resources-page--tablet.webp';
import HelpfulResourcesImgTablet2X from 'images/helpful-resources-page--tablet@2x.webp';
import HelpfulResourcesImgDesktop1X from 'images/helpful-resources-page--desktop.webp';
import HelpfulResourcesImgDesktop2X from 'images/helpful-resources-page--desktop@2x.webp';
import metaImageUrl from 'images/helpful-resources-page--tablet.jpg';

import Intro from 'components/shared/Intro';
import ResponsiveImage from 'components/shared/ResponsiveImage';
import Title from 'components/shared/Title';
import TriangleContainer from 'components/shared/TriangleContainer';
import Seo from 'components/shared/Seo';
import utils from 'common/utils';
import Articles from './Articles';
import helpfulResourcesArticlesJson from './static-articles';

const helpfulResourcesArticles = JSON.parse(helpfulResourcesArticlesJson);

const images = [
  {
    media: 'all and (max-width: 480px)',
    img: HelpfulResourcesImgMobile1X,
    img2x: HelpfulResourcesImgMobile2X,
    type: 'image/webp',
  },
  {
    media: 'all and (max-width: 768px)',
    img: HelpfulResourcesImgTablet1X,
    img2x: HelpfulResourcesImgTablet2X,
    type: 'image/webp',
  },
  {
    media: 'all and (min-width: 769px)',
    img: HelpfulResourcesImgDesktop1X,
    img2x: HelpfulResourcesImgDesktop2X,
    type: 'image/webp',
  },
];

const metaImage = {
  url: utils.getImageFullPath(metaImageUrl),
  width: '737',
  height: '551',
};

const Resources = () => {
  const { t } = useTranslation('resources');
  const media = <ResponsiveImage images={images} altText={t('title')} />;
  const articlesSource = helpfulResourcesArticles || {};

  return (
    <React.Fragment>
      <Seo
        title={t('pageTitle')}
        description={t('pageDescription')}
        image={metaImage}
      />
      <Intro title={t('intro.title')} text={t('intro.text')} media={media} />
      <TriangleContainer underIntro>
        <Title className="col-12 mb-5">{t('subtitle')}</Title>
        <Articles baseUrl="helpful-resources" articles={articlesSource} />
      </TriangleContainer>
    </React.Fragment>
  );
};

export default Resources;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TriangleContainer = ({
  children,
  className,
  theme,
  triangleTheme,
  underIntro,
}) => {
  const containerClasses = classNames('sf-triangle-container position-relative', theme, className, {
    [`bg-${theme}`]: theme,
    under: underIntro,
    [`sf-triangle-container--${triangleTheme}`]: triangleTheme,
  });

  return (
    <section className={containerClasses}>
      <div className="sf-container row">
        {children}
      </div>
    </section>
  );
};

TriangleContainer.defaultProps = {
  children: null,
  theme: 'tertiary',
  triangleTheme: 'tertiary-100',
  className: null,
  underIntro: false,
};

TriangleContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  theme: PropTypes.string,
  triangleTheme: PropTypes.string,
  underIntro: PropTypes.bool,
};

export default TriangleContainer;

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PublicRoute = ({
  component,
  layout: Layout,
  backToTop,
  getReadyButton,
  ...rest
}) => (
  <Route
    {...rest}
    render={
      (routeProps) => (
        <Layout
          {...routeProps}
          backToTop={backToTop}
          getReadyButton={getReadyButton}
          component={component}
        />
      )
    }
  />
);

PublicRoute.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.func.isRequired,
  backToTop: PropTypes.bool,
  getReadyButton: PropTypes.bool,
};

PublicRoute.defaultProps = {
  backToTop: true,
  getReadyButton: true,
};

export default PublicRoute;

import intensity1Img from 'images/intensity1.svg';
import intensity2Img from 'images/intensity2.svg';
import intensity3Img from 'images/intensity3.svg';
import intensity4Img from 'images/intensity4.svg';
import { exercisesVideos } from 'common/videos';

const defaultLevelData = {
  completed: false,
  unlocked: false,
  sublevelsCompleted: [],
  showCompletionModal: true,
};

// Initial local storage object
export const initialProgress = {
  previousLevel: 1,
  currentLevel: 1,
  currentSublevel: null,
  1: {
    ...defaultLevelData,
    unlocked: true,
  },
  2: defaultLevelData,
  3: defaultLevelData,
  4: {
    ...defaultLevelData,
    showCompletionModal: false,
  },
};

// Get level preview image url promise
export const getLevelPreviewPromise = async (levelId, sublevelId) => {
  const previewPromise = await import(`../images/videos/preview/level${levelId}_${sublevelId}.webp`);

  return previewPromise;
};

// Get sublevel thumbnail image promise
const getThumbnailImage = async (levelId, sublevelId) => {
  const thumbnailPromise = await import(`../images/videos/thumbnails/sublevel${levelId}_${sublevelId}.webp`);

  return thumbnailPromise;
};

const getSublevel = (levelId, sublevelId) => ({
  id: sublevelId,
  videoUrl: {
    en: exercisesVideos[`level${levelId}`][`sublevel${sublevelId}`].en,
    fr: exercisesVideos[`level${levelId}`][`sublevel${sublevelId}`].fr,
  },
  thumbnailPromise: getThumbnailImage(levelId, sublevelId),
  title: `video${sublevelId}Title`,
});

const getSublevelsArray = (levelId) => {
  const sublevelsKey = Array.from(Array(5), (e, i) => i + 1);

  return sublevelsKey.map((sublevelId) => getSublevel(levelId, sublevelId));
};

const sublevels = [
  getSublevelsArray(1),
  getSublevelsArray(2),
  getSublevelsArray(3),
  getSublevelsArray(4),
];

export const levels = [
  {
    id: 1,
    intensityImg: intensity1Img,
    color: 'quaternary',
    sublevels: sublevels[0],
  },
  {
    id: 2,
    intensityImg: intensity2Img,
    color: 'tertiary',
    sublevels: sublevels[1],
  },
  {
    id: 3,
    intensityImg: intensity3Img,
    color: 'secondary',
    sublevels: sublevels[2],
  },
  {
    id: 4,
    intensityImg: intensity4Img,
    color: 'primary',
    sublevels: sublevels[3],
  },
];

import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import ExerciseCard from 'components/home/ExerciseCard';
import ExerciseCardImg1 from 'images/exercises/exercise1.svg';
import ExerciseCardImg2 from 'images/exercises/exercise2.svg';
import ExerciseCardImg3 from 'images/exercises/exercise3.svg';
import ExerciseCardImg4 from 'images/exercises/exercise4.svg';
import ExerciseCardImg5 from 'images/exercises/exercise5.svg';

const cards = [
  {
    id: 1,
    title: 'exerciseCard1.title',
    text: 'exerciseCard1.text',
    imgPath: ExerciseCardImg1,
    alt: false,
  },
  {
    id: 2,
    title: 'exerciseCard2.title',
    text: 'exerciseCard2.text',
    imgPath: ExerciseCardImg2,
    alt: false,
  },
  {
    id: 3,
    title: 'exerciseCard3.title',
    text: 'exerciseCard3.text',
    imgPath: ExerciseCardImg3,
    alt: false,
  },
  {
    id: 4,
    title: 'exerciseCard4.title',
    text: 'exerciseCard4.text',
    imgPath: ExerciseCardImg4,
    alt: false,
  },
  {
    id: 5,
    title: 'exerciseCard5.title',
    text: 'exerciseCard5.text',
    imgPath: ExerciseCardImg5,
    alt: false,
  },
  {
    id: 6,
    title: 'exerciseCard6.title',
    text: 'exerciseCard6.text',
    alt: true,
  },
];

const ExerciseTypes = () => {
  const { t } = useTranslation('home');

  return (
    <section className="sf-exercises bg-tertiary">
      <div className="sf-container">
        <h3 className="mx-3 mb-3 font-weight-bold">{t('exerciseTypesTitle')}</h3>
        <div className="row mx-0">
          <p className="sf-exercises__text col-12 col-lg-9 col-xl-8">
            <Trans i18nKey="exerciseTypesText" ns="home" components={{ bold: <strong /> }} />
          </p>
        </div>
        <div className="row mt-4 mt-md-5 mx-0">
          {cards.map((card) => <ExerciseCard data={card} key={card.title} />)}
        </div>
      </div>
    </section>
  );
};

export default ExerciseTypes;

import React from 'react';
import AppContextProvider from 'context/AppContextProvider';
import Router from './router';
import 'styles/base.scss';

const App = () => (
  <div className="container-fluid p-0 overflow-hidden h-100">
    <AppContextProvider>
      <Router />
    </AppContextProvider>
  </div>
);

export default App;

import React from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import classNames from 'classnames';

const ResponsiveImage = ({
  images, altText, defaultImage, className, fluid,
}) => {
  const firstImage = images && images.length && images[0];
  const placeholderImage = defaultImage || firstImage?.img;
  const imagesClasses = classNames(className, { 'img-fluid': fluid });
  return (
    <picture>
      {
        _map(images, ({
          media, img, img2x, type,
        }) => {
          const image2xSrc = img2x ? `, ${img2x} 2x` : '';
          return (<source key={media} srcSet={`${img} 1x${image2xSrc}`} media={media} type={type || 'image/jpeg'} />);
        })
      }
      <img src={placeholderImage} alt={altText} className={imagesClasses} loading="lazy" />
    </picture>
  );
};

ResponsiveImage.defaultProps = {
  altText: null,
  className: null,
  defaultImage: null,
  fluid: true,
};

ResponsiveImage.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    media: PropTypes.string,
    img: PropTypes.string,
    img2x: PropTypes.string,
    type: PropTypes.string,
  })).isRequired,
  defaultImage: PropTypes.string,
  className: PropTypes.string,
  altText: PropTypes.string,
  fluid: PropTypes.bool,
};

export default ResponsiveImage;

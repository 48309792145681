import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _isString from 'lodash/isString';

const Intro = ({
  classes, title, text, media, hideMediaOnMobile,
}) => {
  const titleElement = _isString(title)
    ? <h1 className="font-weight-normal">{title}</h1>
    : title;
  const textElement = _isString(text)
    ? <p>{text}</p>
    : text;
  const mediaElement = _isString(media)
    ? <img src={media} className="img-fluid" alt={_isString(title) ? title : ''} />
    : media;

  const componentClasses = classNames('sf-intro sf-container px-3 position-relative', classes);

  return (
    <section className={componentClasses}>
      <div className="row">
        <div className="left col-lg-6 pr-lg-5">
          {titleElement}
          {textElement}
        </div>
        <div className={classNames('right col-lg-6 mt-5 mt-lg-0', { 'd-none d-lg-block': hideMediaOnMobile })}>
          {mediaElement}
        </div>
      </div>
    </section>
  );
};

Intro.defaultProps = {
  classes: null,
  title: null,
  text: null,
  media: null,
  hideMediaOnMobile: false,
};

Intro.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  media: PropTypes.any,
  hideMediaOnMobile: PropTypes.bool,
};

export default Intro;

// Layouts
import DefaultLayout from 'components/layout/DefaultLayout';
// Components
import Home from 'components/home';
import Exercises from 'components/exercises';
import Resources from 'components/resources';
import Faqs from 'components/faq';
import Recommendations from 'components/recommendations';
import About from 'components/about';
import Privacy from 'components/privacy';
import NotFound from 'components/not-found';

// Paths
import pathNames from 'router/path-names';
import Contact from 'components/contact';

const routes = [
  {
    key: 'home',
    path: [pathNames.home.en, pathNames.home.fr],
    exact: true,
    component: Home,
    layout: DefaultLayout,
  },
  {
    key: 'exercises',
    path: [pathNames.exercises.en, pathNames.exercises.fr],
    exact: true,
    getReadyButton: false,
    component: Exercises,
    layout: DefaultLayout,
  },
  {
    key: 'resources',
    path: [pathNames.resources.en, pathNames.resources.fr],
    exact: true,
    component: Resources,
    layout: DefaultLayout,
  },
  {
    key: 'faq',
    path: [pathNames.faq.en, pathNames.faq.fr],
    exact: true,
    component: Faqs,
    layout: DefaultLayout,
  },
  {
    key: 'recommendations',
    path: [pathNames.recommendations.en, pathNames.recommendations.fr],
    exact: true,
    component: Recommendations,
    layout: DefaultLayout,
  },
  {
    key: 'about',
    path: [pathNames.about.en, pathNames.about.fr],
    exact: true,
    component: About,
    layout: DefaultLayout,
  },
  {
    key: 'privacy-disclaimer',
    path: [pathNames.privacy.en, pathNames.privacy.fr],
    exact: true,
    getReadyButton: false,
    component: Privacy,
    layout: DefaultLayout,
  },
  {
    key: 'contact',
    path: [pathNames.contact.en, pathNames.contact.fr],
    exact: true,
    getReadyButton: false,
    component: Contact,
    layout: DefaultLayout,
  },
  {
    key: 'not-found',
    path: '*',
    component: NotFound,
    layout: DefaultLayout,
  },
];

export default routes;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import playIcon from 'images/play.svg';
import lockIcon from 'images/lock.svg';

const PlayerButton = ({ type }) => {
  const { t } = useTranslation('exercises');

  return (
    <button className="sf-exercises-button position-relative rounded-circle btn border-0 p-0" type="button">
      {type === 'play' ? (
        <img src={playIcon} alt={t('playVideo')} className="sf-exercises-button__img sf-exercises-button__img--play" />
      ) : (
        <img src={lockIcon} alt={t('unlockLevel')} className="sf-exercises-button__img sf-exercises-button__img--lock" />
      )}
    </button>
  );
};

PlayerButton.propTypes = {
  type: PropTypes.string.isRequired,
};

export default PlayerButton;

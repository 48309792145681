import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Seo from 'components/shared/Seo';

import Title from '../shared/Title';

const LANG_NS = 'privacy';

const Recommendations = () => {
  const { t } = useTranslation(LANG_NS);

  return (
    <section className="sf-privacy container mt-2 mt-lg-6">
      <Seo
        title={t('pageTitle')}
        description={t('pageDescription')}
      />
      <h1 className="text-primary-dark mb-4 mb-lg-6 mt-4 mt-md-6">{t('privacy.title')}</h1>
      <Title className="mt-4 mb-3 mt-lg-5 mb-lg-4" size={2}>{t('privacy.personalInformation.title')}</Title>
      <p>
        <Trans i18nKey="privacy.personalInformation.text" ns={LANG_NS} />
      </p>
      <Title className="mt-4 mb-3 mt-lg-5 mb-lg-4" size={2}>{t('privacy.privacyPractices.title')}</Title>
      <p>
        <Trans i18nKey="privacy.privacyPractices.text" ns={LANG_NS} />
      </p>

      <h1 className="text-primary-dark mb-4 mb-lg-6 mt-4 mt-md-6">{t('disclaimer.title')}</h1>
      <Title className="mt-4 mb-3 mt-lg-5 mb-lg-4" size={2}>{t('disclaimer.medical.title')}</Title>
      <p>
        <Trans i18nKey="disclaimer.medical.text" ns={LANG_NS} />
      </p>
    </section>
  );
};

export default Recommendations;

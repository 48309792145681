import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as ErrorIcon } from 'images/exclamation-circle.svg';

const getField = (fieldParams) => {
  const {
    type, register, error, options, ...restParams
  } = fieldParams;

  switch (type) {
    case 'text':
    case 'email':
      return (
        <input
          type={type}
          className={classNames(
            'sf-form__field form-control',
            { 'sf-form__field-error': error },
          )}
          aria-invalid={error ? 'true' : 'false'}
          {...register}
          {...restParams}
        />
      );
    case 'radio':
      return (
        <label className="sf-form__radio-label col-12 col-lg-8" htmlFor={restParams.id}>
          {restParams.placeholder}
          <input
            type="radio"
            className={classNames(
              'sf-form__field form-control',
              { 'sf-form__field-error': error },
            )}
            value={restParams.label}
            {...register}
            {...restParams}
          />
          <span className="sf-form__radio-label__icon" />
        </label>
      );
    case 'select':
      return (
        <select
          className={classNames(
            'sf-form__field form-control custom-select custom-select-lg',
            { 'sf-form__field-error': error },
          )}
          aria-invalid={error ? 'true' : 'false'}
          {...register}
          {...restParams}
        >
          <option value="">{restParams.placeholder}</option>
          {options
            && options.map((option) => <option key={`option-${option.id}`} value={option.value}>{option.value}</option>)}
        </select>
      );
    case 'textarea':
      return (
        <textarea
          className={classNames(
            'sf-form__field form-control',
            { 'sf-form__field-error': error },
          )}
          aria-invalid={error ? 'true' : 'false'}
          rows={5}
          {...register}
          {...restParams}
        />
      );
    default:
      return null;
  }
};

const FormField = (props) => {
  const { label, error, ...otherParams } = props;

  return (
    <div className="sf-form__group form-groups">
      { otherParams.type !== 'radio' && <label htmlFor={otherParams.id} className="sf-form__label ml-1">{label}</label> }
      <div className="sf-form__field-wrapper position-relative">
        { getField({ ...props }) }
        { error && <ErrorIcon className="sf-form__error-icon" />}
      </div>
      {error && <p className="sf-form__message mt-2" role="alert">{error[1].message}</p>}
    </div>
  );
};

FormField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  register: PropTypes.object.isRequired,
  error: PropTypes.array,
};

FormField.defaultProps = {
  error: undefined,
  label: '',
  placeholder: '',
};

export default FormField;

import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _get from 'lodash/get';
import lockIcon from 'images/lock.svg';
import checkIcon from 'images/checkmark.svg';
import LevelProgressContext from 'context/LevelProgressContext';
import UnlockLevelContext from 'context/UnlockLevelContext';
import utils from 'common/utils';

const Sublevel = (props) => {
  const {
    id, title, levelId, thumbnailPromise,
  } = props;
  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    thumbnailPromise.then((url) => {
      setThumbnailUrl(url.default);
    });
  }, [thumbnailPromise]);

  const { t } = useTranslation('exercises');
  const { levelProgress, setLevelProgress } = useContext(LevelProgressContext);
  const { setLevelToUnlock } = useContext(UnlockLevelContext);
  const current = _get(levelProgress, 'currentSublevel');
  const sublevelsCompleted = _get(levelProgress[levelId], 'sublevelsCompleted', []);
  const completed = sublevelsCompleted.includes(id);
  const unlocked = _get(levelProgress, `${levelId}.unlocked`);
  const isSelected = current?.id === id && current?.levelId === levelId;

  const updateVideo = () => {
    const updatedProgress = utils.setCurrentSublevel(levelProgress, props, levelId);
    setLevelProgress(updatedProgress);
  };

  const showUnlockModal = () => {
    setLevelToUnlock(levelId);
  };

  const onSublevelClick = () => {
    if (unlocked) {
      updateVideo();
    } else {
      showUnlockModal();
    }
  };

  return (
    <div
      className={classNames(
        'sf-exercises-sublevel',
        { 'sf-exercises-sublevel--selected': isSelected },
      )}
      onClick={onSublevelClick}
      onKeyDown={updateVideo}
      role="button"
      tabIndex="0"
    >
      <div className="sf-exercises-sublevel__wrapper position-relative overflow-hidden d-flex align-items-end h-100 w-100">
        <img className="sf-exercises-sublevel__image h-100 w-100" src={thumbnailUrl} alt={t(title)} />
        {!unlocked && (
          <img src={lockIcon} alt={t(title)} className="sf-exercises-sublevel__icon position-absolute" />
        )}
        {completed && (
          <img src={checkIcon} alt={t(title)} className="sf-exercises-sublevel__icon sf-exercises-sublevel__icon--check position-absolute" />
        )}
        <h4 className="sf-exercises-sublevel__title text-white position-absolute font-weight-bold">{t(title)}</h4>
      </div>
    </div>
  );
};

Sublevel.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  levelId: PropTypes.number.isRequired,
  thumbnailPromise: PropTypes.object.isRequired,
};

export default Sublevel;

import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Arrow from 'images/arrow-forward.svg';

export const ARROW_LINK_SIZE = {
  BIG: 'big',
};

const ArrowLink = ({ size, to, children }) => {
  const containerClasses = classNames('arrow-link', size);

  return (
    <Link to={to} className={containerClasses}>
      {children}
      <img className="arrow" src={Arrow} alt="forward arrow" />
    </Link>
  );
};

ArrowLink.propTypes = {
  to: PropTypes.string,
  size: PropTypes.oneOf([
    ARROW_LINK_SIZE.BIG,
  ]),
  children: PropTypes.any,
};
ArrowLink.defaultProps = {
  to: '',
  size: ARROW_LINK_SIZE.BIG,
  children: [],
};

export default ArrowLink;

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Intro from 'components/shared/Intro';
import ResponsiveImage from 'components/shared/ResponsiveImage';

import introImgMobile1X from 'images/about/about--mobile.webp';
import introImgMobile2X from 'images/about/about--mobile@2x.webp';
import introImgTablet1X from 'images/about/about--tablet.webp';
import introImgTablet2X from 'images/about/about--tablet@2x.webp';
import introImgDesktop1X from 'images/about/about--desktop.webp';
import introImgDesktop2X from 'images/about/about--desktop@2x.webp';

const images = [
  {
    media: 'all and (max-width: 480px)',
    img: introImgMobile1X,
    img2x: introImgMobile2X,
    type: 'image/webp',
  },
  {
    media: 'all and (max-width: 768px)',
    img: introImgTablet1X,
    img2x: introImgTablet2X,
    type: 'image/webp',
  },
  {
    media: 'all and (min-width: 769px)',
    img: introImgDesktop1X,
    img2x: introImgDesktop2X,
    type: 'image/webp',
  },
];

const Introduction = () => {
  const { t } = useTranslation('exercises');
  const media = <ResponsiveImage images={images} altText={t('title')} />;

  const title = (
    <h1 className="font-weight-normal">
      <Trans i18nKey="title" ns="exercises" components={{ bold: <strong /> }} />
    </h1>
  );

  const text = (
    <React.Fragment>
      <p>{t('intro1')}</p>
      <p><Trans i18nKey="intro2" ns="exercises" components={{ bold: <strong /> }} /></p>
      <p>{t('intro3')}</p>
      <p><Trans i18nKey="intro4" ns="exercises" components={{ bold: <strong /> }} /></p>
    </React.Fragment>
  );

  return (
    <Intro
      title={title}
      text={text}
      media={media}
      hideMediaOnMobile
    />
  );
};

export default Introduction;

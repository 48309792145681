import React from 'react';
import { useTranslation } from 'react-i18next';
import Introduction from 'components/home/Introduction';
import HowItWorks from 'components/home/HowItWorks';
import ExerciseTypes from 'components/home/ExerciseTypes';
import RightForYou from 'components/home/RightForYou';
import HelpfulResources from 'components/home/HelpfulResources';
import Testimonials from 'components/home/Testimonials';
import Seo from 'components/shared/Seo';
import utils from 'common/utils';
import metaImageUrl from 'images/how-it-works--tablet.jpg';

const metaImage = {
  url: utils.getImageFullPath(metaImageUrl),
  width: '737',
  height: '551',
};

const Home = () => {
  const { t } = useTranslation('home');

  return (
    <React.Fragment>
      <Seo
        title={t('pageTitle')}
        description={t('pageDescription')}
        image={metaImage}
      />
      <Introduction />
      <HowItWorks />
      <ExerciseTypes />
      <RightForYou />
      <HelpfulResources />
      <Testimonials />
    </React.Fragment>
  );
};

export default Home;

import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Trans } from 'react-i18next';
import utils from 'common/utils';
import PlayerButton from 'components/exercises/PlayerButton';
import { homepageVideos } from 'common/videos';
import previewUrl from '../../images/videos/preview/doctor_video_preview.webp';

const Introduction = () => {
  const lang = utils.getLang();
  const [playing, setPlaying] = useState(false);

  const onClickPreview = () => {
    setPlaying(true);
  };

  return (
    <section className="sf-container sf-home px-3 position-relative">
      <h1 className="sf-home__title mb-3 mb-md-4 mb-lg-5">
        <Trans i18nKey="title" ns="home" components={{ bold: <strong /> }} />
      </h1>
      <div className="row">
        <div className="sf-home__intro-container col-12 col-lg-6 pr-lg-5">
          <p className="sf-home__intro">
            <Trans i18nKey="intro1" ns="home" components={{ bold: <strong /> }} />
          </p>
          <p className="sf-home__intro">
            <Trans i18nKey="intro2" ns="home" components={{ bold: <strong /> }} />
          </p>
          <p className="sf-home__intro">
            <Trans i18nKey="intro3" ns="home" components={{ bold: <strong /> }} />
          </p>
        </div>
        <div className="sf-home__video-container col-12 col-lg-6 mb-5 mb-lg-0 position-relative">
          <ReactPlayer
            className="sf-home__video"
            url={homepageVideos[lang]}
            playing={playing}
            controls
            width="100%"
            height="100%"
            light={previewUrl}
            playIcon={<PlayerButton type="play" />}
            config={{
              youtube: {
                playerVars: {
                  playsinline: true,
                  color: '#FFFFFF',
                },
              },
            }}
            onClickPreview={onClickPreview}
          />
        </div>
      </div>
    </section>
  );
};

export default Introduction;
